import { permissionsActions } from '../../_actions';
import { PERMISSION_STATUS } from '../../_constants';
import { store } from '../../_store';

export class PermissionsHelper {
  static hasGrantedStatus(status) {
    return status === PERMISSION_STATUS.GRANTED;
  }

  static hasDeniedStatus(status) {
    return status === PERMISSION_STATUS.DENIED;
  }

  static hasDefaultStatus(status) {
    return status === PERMISSION_STATUS.DEFAULT;
  }

  static hasPromptStatus(status) {
    return status === PERMISSION_STATUS.PROMPT;
  }

  static setNotificationPermissionStatus(status) {
    return store.dispatch(
      permissionsActions.setNotificationPermissionStatus(status)
    );
  }
  static resetNotificationPermissionStatus() {
    return store.dispatch(
      permissionsActions.resetNotificationPermissionStatus()
    );
  }
}
