import isNil from "lodash/isNil";
import moment from "moment";
import { commonsActions, permissionsActions } from "../../_actions";
import { PERMISSION_STATUS, AnalyticsEvents } from "../../_constants";
import { store } from "../../_store";
import { debug } from "../debug";
import { Notifications } from "../notifications";
import { PermissionsHelper } from "../permissions/permissions.helper";
import { Tenants } from "../../_helpers/tenants";
import { Commons } from "../commons";
import environmentConstants from "../../_constants/environment/environment.constants";
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";
import { compareSemVer, isValidSemVer } from "semver-parser";

const NUVIU_BASE_VERSION = "2.0.0";

export default class BrowserHelper {
  static isBLEBrowser() {
    return (
      navigator.userAgent.indexOf("BLE Browser") >= 0 ||
      navigator.userAgent.indexOf("Nuviu") >= 0
    );
  }

  static isBrowserRunningInBackground() {
    return document.hidden;
  }

  static isBrowserRunningInForeground() {
    return !this.isBrowserRunningInBackground();
  }

  static isBLEBrowserRunningInBackground() {
    return this.isBLEBrowser() && this.isBrowserRunningInBackground();
  }

  static getBLEBrowserVersion() {
    return navigator.userAgent.indexOf("BLE Browser") >= 0
      ? navigator.userAgent.split("BLE Browser/")[1]
      : navigator.userAgent.indexOf("Nuviu") >= 0
      ? navigator.userAgent.split("Nuviu/")[1]
      : "";
  }

  static isAccessDenied() {
    return (
      process.env.REACT_APP_ENV_NAME === environmentConstants.PRODUCTION &&
      /*|| process.env.REACT_APP_ENV_NAME === environmentConstants.STAGING*/
      /*|| process.env.REACT_APP_ENV_NAME === environmentConstants.DEVELOPMENT*/
      (!this.isBLEBrowser() || navigator.userAgent.indexOf("iPad") !== -1) &&
      !Commons.isLocalEnvironment()
    );
  }

  /**********************************************
      NOTIFICATION
    ***********************************************/
  static checkOSNotificationPermissionStatusOnly() {
    if (
      this.isBLEBrowser()
    ) {
      return new Promise((resolve, reject) => {
        navigator.permissions.query({name: 'notifications', system: true})
          .then((permission) => {
            resolve(permission?.state)
          })
          .catch((e) => {
            reject()
          })
      })
    }
  }

  // it will prompt the message for the user asking if it wants to enable the local notifications
  static checkOSNotificationPermissionStatus() {
    if (
      this.isBLEBrowser()
    ) {
      return new Promise((resolve) => {
        navigator.permissions.query({name: 'notifications', system: true})
          .then((permission) => {
            debug(
              `[Nuviu] checkOSNotificationPermissionStatus ${permission?.state}`
            );

            if (!PermissionsHelper.hasGrantedStatus(permission?.state)) {
              navigator.permissions.request({name: 'notifications', system: true})
                .then((response) => {
                  debug(
                    `[Nuviu] enableSystemPermission status`,
                    JSON.stringify(response)
                  );
                  resolve(response);
                })
                .catch((e) => {
                  debug(
                    `[Nuviu] error on enabling system notif permission `,
                    e?.message
                  );
                });
            } else {
              resolve(permission);
            }
          })
          .catch((e) => {
            debug(
              `[Nuviu] error on checking system notif permission `,
              e?.message
            );
          });
      }).then((response) => {
        const { state: newOSPermissionStatus } = response;

        const {
          permissionsReducer: { OSNotificationPermissionStatus },
        } = store.getState();

        // if the user has changed the OS notification permission
        if (OSNotificationPermissionStatus !== newOSPermissionStatus) {
          if (PermissionsHelper.hasGrantedStatus(newOSPermissionStatus)) {
            // if the user has changed the OS notification permission to granted
            logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_OS_PERMISSION, {
              status: PERMISSION_STATUS.GRANTED,
            });

            debug(`[Nuviu] the user has granted OS notification permission!!`);
          } else if (PermissionsHelper.hasDeniedStatus(newOSPermissionStatus)) {
            // if the user has changed the OS notification permission to granted
            logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_OS_PERMISSION, {
              status: PERMISSION_STATUS.DENIED,
            });

            debug(`[Nuviu] the user has denied OS notification permission!!`);
          }

          // update the OSNotificationPermissionStatus in redux store
          store.dispatch(
            permissionsActions.setOSNotificationPermissionStatus(
              newOSPermissionStatus
            )
          );
        }

        return response;
      });
    } else {
      debug(`ERROR: checkOSNotificationPermissionStatus is not available`);
      return Promise.reject(
        "ERROR: checkOSNotificationPermissionStatus is not available"
      );
    }
  }

  // display a local notification
  static createNotification(notification) {
    debug(`[Notification] createNotification`, JSON.stringify(notification));
    return this.checkNotificationPermissionForDevice(notification?.device)
      .then((permissionGranted) => {
        debug(
          `[Notification] checkNotificationPermissionForDevice permissionGranted`,
          permissionGranted
        );
        if (permissionGranted) {
          if (
            this.isBLEBrowser() &&
            !!window?.bleb?.Notification?.sendNotification
          ) {
            const title = Notifications.stringReplace(
              notification,
              notification.title
            );
            const body = Notifications.stringReplace(
              notification,
              notification.body
            );
            return this.checkOSNotificationPermissionStatus().then(
              ({ state }) => {
                if (PermissionsHelper.hasGrantedStatus(state)) {
                  debug(
                    `[Nuviu] create Notification -> title: ${title}, body: ${body}`
                  );
                  window.bleb.Notification.sendNotification(
                    { title, body },
                    notification?.device?.deviceInfo?.peripheralId
                  );
                }

                return notification;
              }
            );
          } else {
            debug(`ERROR: sendNotification is not available`);
            return Promise.reject("ERROR: sendNotification is not available");
          }
        } else {
          debug(
            `ERROR: permission not granted for device ${notification?.device?.deviceName}`
          );
          return Promise.reject(
            `ERROR: permission not granted for device ${notification?.device?.deviceName}`
          );
        }
      })
      .catch((e) => {
        debug(
          `ERROR: got error from checkNotificationPermissionForDevice method`,
          e
        );
        return Promise.reject(
          `ERROR: got error from checkNotificationPermissionForDevice method`
        );
      });
  }

  // check if the user has granted the remote push notifications
  static checkPWANotificationPermissionStatus() {
    // const {
    //   permissionsReducer: { notificationPermissionStatus },
    // } = store.getState();

    // if (!!notificationPermissionStatus) {
    //   return Promise.resolve({ status: notificationPermissionStatus });
    // } else 
    if (
      this.isBLEBrowser()
    ) {
      return navigator.permissions.query({name: 'notifications', system: false})
        .then((response) => {
          if (
            PermissionsHelper.hasGrantedStatus(response.state) ||
            PermissionsHelper.hasDeniedStatus(response.state)
          ) {
            PermissionsHelper.setNotificationPermissionStatus(response.state);
          }
          debug(
            `[Nuviu] checkPWANotificationPermissionStatus ${JSON.stringify(
              response
            )}`
          );
          return response;
        })
        .catch((err) => {
          debug(
            `ERROR: [Nuviu] checkPWANotificationPermissionStatus ${JSON.stringify(
              err
            )}`
          );
          return Promise.reject(err);
        });
    } else {
      debug(`ERROR: checkPWANotificationPermissionStatus is not available`);
      return Promise.reject(
        "ERROR: checkPWANotificationPermissionStatus is not available"
      );
    }
  }

  // it will prompt the message for the user asking if it wants to enable the remote push notifications
  static enablePWANotificationPermission() {
    if (
      this.isBLEBrowser()
    ) {
      return navigator.permissions.query({name: 'notifications', system: false})
        .then((response) => {
          const { state, token, errorMessage } = response;

          PermissionsHelper.setNotificationPermissionStatus(state);

          if (PermissionsHelper.hasGrantedStatus(state)) {
            if (!isNil(token)) {
              return this.uploadPWATokenToIoTPlatform(token)
                .then(() => {
                  return response;
                })
                .catch((err) => {
                  return Promise.reject({
                    state: "error",
                    errorMessage: err,
                  });
                });
            } else {
              return Promise.reject({ state: "error", errorMessage });
            }
          } else {
            return navigator.permissions.request({name: 'notifications', system: false})
              .then((response) => {
                debug(
                  `[Nuviu] enableBrowserPermission state`,
                  JSON.stringify(response)
                );
                return {
                  state: response.status,
                  token: response.token
                }
              })
              .catch((e) => {
                debug(
                  `[Nuviu] error on enabling browser notif permission `,
                  e?.message
                );
                return Promise.reject({ state: "error", errorMessage: e?.message });
              });
          }
        })
        .catch((err) => {
          //alert(JSON.stringify(err));
          debug(
            `ERROR: [Nuviu] enablePushNotifications ${JSON.stringify(err)}`
          );
          return Promise.reject({
            state: "error",
            errorMessage: `ERROR: [Nuviu] enablePushNotifications ${JSON.stringify(
              err
            )}`,
          });
        });
    } else {
      debug(`ERROR: enablePWANotificationPermission is not available`);
      return Promise.reject({
        state: "error",
        errorMessage: `ERROR: enablePWANotificationPermission not available`,
      });
    }
  }

  static checkNotificationPermissionForDevice(device) {
    return new Promise((resolve, reject) => {
      if (
        this.isBLEBrowser()
      ) {
        navigator.permissions.query({name: 'notifications', system: false})
          .then((response) => {
            resolve(
              PermissionsHelper.hasGrantedStatus(response.state)
            )
          })
          .catch((e) => {
            debug(
              `ERROR: checkLocalNotificationBrowserPermissions is getting error`,
              e
            );
            reject(
              `ERROR: checkLocalNotificationBrowserPermissions is getting error`
            );
          });
      } else {
        debug(`ERROR: checkLocalNotificationBrowserPermissions not supported`);
        reject(`ERROR: checkLocalNotificationBrowserPermissions not supported`);
      }
    });
  }

  static uploadPWATokenToIoTPlatform(token) {
    return store.dispatch(commonsActions.setDevices(token));
  }

  static removePWATokenFromIoTPlatformAction() {
    return (dispatch) => {
      debug("start removePWATokenFromIoTPlatform");
      return dispatch(commonsActions.setDevices(null)).then(() => {
        debug("Successfully removePWATokenFromIoTPlatform");
      });
    };
  }

  static removePWATokenFromIoTPlatform() {
    return store.dispatch(this.removePWATokenFromIoTPlatformAction());
  }

  /**********************************************
      LOCATION
    ***********************************************/
  /**
   * Method to get if current browser (not nuviu) can retrieve lcoation
   * @returns true or false
   */
  static isGetCurrentPositionFromGeolocationAvailable() {
    return typeof navigator?.geolocation?.getCurrentPosition === "function";
  }

  /**
   * Method to get location value for not niviu browser (also permission, implicit)
   * @returns
   */
  static getCurrentPositionFromGeolocationAsync() {
    return new Promise((resolve, reject) => {
      if (this.isGetCurrentPositionFromGeolocationAvailable()) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
        /*window.bleb.Location.requestDeviceLocation(
          device?.deviceInfo?.peripheralId
        )
          .then(resolve)
          .catch((e) => {
            debug(`ERROR: requestDeviceLocation is not Available`, e);
            reject("ERROR: requestDeviceLocation is not Available");
          });*/
      } else {
        debug(
          `ERROR: navigator.geolocation.getCurrentPosition is not Available`
        );
        reject(
          "ERROR: navigator.geolocation.getCurrentPosition is not Available"
        );
      }
    });
  }

  /**
   * Method to get location permission for niviu browser (internal)
   * @returns
   */
  static getPermissionFromBLEBrowserAsync() {
    return new Promise((resolve, reject) => {
      if (this.isBLEBrowser()) {
        navigator.permissions.query({name: 'geolocation', system: true})
          .then((response) => {
            debug(
              `[getPermissionFromBLEBrowserAsync] checkSystemPermission premission`,
              response
            );
            resolve(response);
          })
          .catch((e) => {
            debug(`ERROR: checkSystemPermission is getting error`, e);
            reject("ERROR: checkSystemPermission is getting error");
          });
      } else {
        debug(`ERROR: checkSystemPermission is not Available`);
        reject("ERROR: checkSystemPermission is not Available");
      }
    });
  }

  /**
   * Method to get location permission for all browsers
   * @returns
   */
  static getLocationPermission() {
    return new Promise((resolve) => {
      if (this.isBLEBrowser()) {
        this.getPermissionFromBLEBrowserAsync().then((response) => {
            debug(`[Nuviu] getLocationPermission ${response.state}`);
            if (PermissionsHelper.hasGrantedStatus(response.state)) {
              navigator.permissions.query({ name: 'geolocation', system: false }).then((response) => {
                resolve(response.state)
              })
            } else {
              resolve(response.state)
            }
          }
        );
      } else {
        navigator.permissions.query({ name: 'geolocation' }).then((response) => {
          resolve(response?.state)
        })
      }
    })
  }

  /**
   * Method to get location value for all browsers
   * @returns
   */
  static getCurrentPosition() {
    return this.getLocationPermission().then((permission) => {
      if (PermissionsHelper.hasGrantedStatus(permission)) {
        
        return this.getCurrentPositionFromGeolocationAsync().then((pos) => {
          debug(
            `[geolocation API] => latitude: ${pos.coords.latitude} and longitude ${pos.coords.longitude}`
          );
          return {
            coords: {
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude
            },
            lastSyncedDate: new Date(pos.timestamp),
          };
        })
        .catch(error => {
          //alert('error ' + error.message)
        });
      } else {
        debug(`Location permission is denied`);
        return Promise.reject("Location permission is denied");
      }
    });
  }

  /**********************************************
      AGENT
    ***********************************************/

  static getAgentInfo() {
    if (this.isBLEBrowser()) {
      if (window.bleb !== undefined && window.bleb.Agent !== undefined) {
        return window.bleb.Agent.requestAgentInfo();
      } else {
        return false;
      }
    }

    // // Only for testing in browsers different from Nuviu
    // return new Promise((resolve) =>
    //   setTimeout(
    //     () =>
    //       resolve({
    //         agentInfo: {
    //           os_version: "iOS 15.5",
    //           device_model: "iPhone",
    //           nuviu_version: "1.0.6",
    //         },
    //       }),
    //     500
    //   )
    // );
  }

  static async isUpdateNeeded() {

    if (Commons.isLocalEnvironment() || process.env.REACT_APP_ENV_NAME === environmentConstants.STAGING || process.env.REACT_APP_ENV_NAME === environmentConstants.DEVELOPMENT) {
      return false;
    }

    const res = await this.getAgentInfo();

    if (res == false || res == null || res == undefined) {
      return true;
    }

    const version = res?.agentInfo?.nuviu_version;

    if (version === "1") {
      return true;
    }

    if (isValidSemVer(version)) {
      return compareSemVer(version, NUVIU_BASE_VERSION) === -1;
    }

    return false;
  }
}
