import React from 'react';
import { connect } from 'react-redux';
import { ContactPanel, CustomLoaderOverlay, Header, NotificationOptions, SpinnerModal } from '../../_components';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import _ from 'lodash';
import { Commons } from '../../_helpers/commons';
import { commonsActions, getCustomerId, getVuseUuid, setDoneNotificationPreferences, userActions } from '../../_actions';
import { aemPages, flagStatus, CUSTOMER, thingVuseProperties, AnalyticsEvents, AnalyticsFieldNames, crmUserSettings, routingConstants } from '../../_constants';
import { store } from '../../_store';
import { getAnalyticStatusBy, logAnalyticsEvent, logThingsInAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { Notifications } from '../../_helpers/notifications'
import BrowserHelper from '../../_helpers/browser/browser.helper';
import { ThingsHelper } from '../../_helpers/things';
import { NotificationPermissionHandler } from '../../_components/NotificationPermissionPanels/NotificationPermissionHandler';
import { debug } from '../../_helpers/debug';
import { Tenants } from '../../_helpers/tenants';
import { isNil } from "lodash";
import { history } from '../../_helpers/history';
import { buildURI } from '../../_helpers/navigation';
import { convertObjectToQueryString } from '../../_helpers/utils';
import { AxiosCancelTokensHolder } from '../../_helpers/api/axiosCancelTokensHolder';
import { NotificationPermissions } from '../NotificationPermissions';
class ManageNotifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            needToAskUserForNotificationPermission: false,
            options: {},
            preferences: [],
            oldPreferences: [],
            updateCrm: false,
            prefToSave: null,
            isOpenContactPanel: false,
            notifPermissionActivePanelType: null,
            crmPreferences: {
                // emailSubscriptionVype: false,
                // userOptedInForEmails: false,
                userOptedInForPushNotifications: false,
                // userOptedInForSMS: false,
            },
            currentCrmPreferences: { //temp preferences
                // emailSubscriptionVype: false,
                // userOptedInForEmails: false,
                userOptedInForPushNotifications: false,
                // userOptedInForSMS: false,
            },
            spinnerLoading: false,
            spinnerError: false,
            isLoading: true
        }

        this.handleSavePreferences = this._handleSavePreferences.bind(this);
        this.aem = new AEMHelper();
        this.page = this.aem.getPage(aemPages.NOTIFICATION_PREFERENCES_MENU);
        this.getAEMComponent = this._getAEMComponent.bind(this);
        this.updatePreference = this._updatePreference.bind(this);
        this.handleBack = this._handleBack.bind(this);
        this.setCrmMarketingPreference = this._setCrmMarketingPreference.bind(this);
        this.setNotifPermissionActivePanelType = this._setNotifPermissionActivePanelType.bind(this);


        this.dictionary = {
            ...this.aem.getDictionary(aemPages.NOTIFICATION_PREFERENCES_MENU, {
                NOTIF_PREF_HEADER: propertyHeadingDefaultEmpty,
                NOTIF_FW_UPDATE_BODY: propertyTextDefaultEmptyParsed,
                NOTIF_FW_UPDATE_TITLE: propertyTextDefaultEmpty,
                NOTIF_MYVUSE_WORLD_BODY: propertyTextDefaultEmpty,
                DISCOVER_NOTIFICATION: propertyTextDefaultEmpty,
                NOTIF_CHECK_INFO: propertyTextDefaultEmpty,
                NOTIF_SUGGESTIONS_TITLE: propertyTextDefaultEmpty,
                NOTIF_DEVICE_ERROR_TITLE: propertyTextDefaultEmpty,
                NOTIF_CHECK_EMAIL: propertyTextDefaultEmpty,
                NOTIF_SUGGESTIONS_BODY: propertyTextDefaultEmpty,
                NOTIF_DEVICE_CONNECTION_TITLE: propertyTextDefaultEmpty,
                NOTIF_DEVICE_CONNECTION_BODY: propertyTextDefaultEmptyParsed,
                NOTIF_MARKETING_DISCLAIMER: propertyTextDefaultEmptyParsed,
                NOTIF_SAVE_BTN_CHOOSE: propertyCtaItemsDefaultEmpty,
                NOTIF_DEVICE_ERROR_BODY: propertyTextDefaultEmpty,
                NOTIF_MARKETING_LABEL: propertyTextDefaultEmpty,
                NOTIF_CHECK_SMS: propertyTextDefaultEmpty,
                NOTIF_BACK_STOCK_BODY: propertyTextDefaultEmpty,
                DEVICE_NOTIFICATION: propertyTextDefaultEmpty,
                NOTIF_MKT_DISCLAIMER_OLD: propertyTextDefaultEmpty,
                NOTIF_VUSE_INSIDERS_CLUB: propertyTextDefaultEmptyParsed,
                NOTIF_CUSTOMER_NOTE: propertyTextDefaultEmptyParsed
            }),
            ...this.aem.getDictionary(aemPages.ONBOARD_NOTIF_PREF, {
                NOTIF_PREF_MKT_INFO_CONTENT: propertyTextDefaultEmptyParsed,
                NOTIF_PREF_PRIVACY_LINK: propertyTextDefaultEmptyParsed,
                NOTIF_PREF_MKT_NOTIFICATIONS_TITLE: propertyTextDefaultEmptyParsed
            })
        }
    }

    componentDidMount() {
        if (!this.props.isUpdatingThingsToIoTPlatform) {
            this.loadingPreferences();
        }
    }

    componentWillUnmount() { }

    componentDidUpdate(prevPros, prevState) {
        if (prevState.crmPreferences !== this.state.crmPreferences) {
            console.log("this.state.crmPreferences", this.state.crmPreferences);
        }
        if (prevState.currentCrmPreferences !== this.state.currentCrmPreferences) {
            console.log("this.state.currentCrmPreferences", this.state.currentCrmPreferences);
        }
        if (!!prevPros.isUpdatingThingsToIoTPlatform && !this.props.isUpdatingThingsToIoTPlatform) {
            this.loadingPreferences();
        }
    }

    loadingPreferences() {
        const availableOptions = Notifications.getAvailableNotificationsPrefsForCurrentOS();

        const currentPreferences = availableOptions.map(option => {
            return { type: option.command, data: Commons.getCustomerProperty(option.command) ?? flagStatus.REFUSED };
        })

        let newState = {
            options: _.groupBy(availableOptions, "type") ?? {},
            preferences: currentPreferences,
            oldPreferences: currentPreferences
        }

        const { getUserInfoAsync } = this.props;
        getUserInfoAsync(getCustomerId()).then(response => {
            debug("[componentDidMount] [getUserInfo] response ", response);
            if (response?.user) {
                const crmPreferences = {
                    // emailSubscriptionVype: !!response.user?.emailSubscriptionVype,
                    // userOptedInForEmails: !!response.user?.userOptedInForEmails,
                    userOptedInForPushNotifications: !!response.user?.userOptedInForPushNotifications,
                    // userOptedInForSMS: !!response.user?.userOptedInForSMS
                }
                newState = {
                    ...newState,
                    crmPreferences: {...crmPreferences},
                    currentCrmPreferences: {...crmPreferences}
                }
            }

            this.setState({isLoading: false});

            return response;
        }).catch(() => {
            this.setState({ spinnerError: true });
        }).finally(() => {
            debug("[componentDidMount] [getUserInfo] update state ", newState);
            this.setState(newState);
        });
    }

    _updatePreference(type, accepted) {

        //remove from preferences the preference with current type
        //then insert it with new value
        const filteredPreferences = this.state.preferences.filter((preference) => preference.type !== type);
        const pref = { type, data: accepted ? flagStatus.ACCEPTED : flagStatus.REFUSED };
        this.setState({
            preferences: [
                ...filteredPreferences,
                { type, data: accepted ? flagStatus.ACCEPTED : flagStatus.REFUSED }
            ],
            prefToSave: pref,
            updateCrm: false
        },
        () => this.handleSavePreferences()
        );
    }


    _handleSavePreferences() {
        const { prefToSave, oldPreferences, preferences, crmPreferences, currentCrmPreferences } = this.state;
        console.log('[_handleSavePreferences] prefToSave', prefToSave);

        if(ThingsHelper.areAcceptedAnyPushNotificationThings(preferences, currentCrmPreferences)){
            this.askUserTheNotificationPermission()
        } else {
            // if the old preferences has some push notifications accepted it means the token has been pushed to the server
            // remove it from the server
            if(ThingsHelper.areAcceptedAnyPushNotificationThings(oldPreferences, crmPreferences)){
                BrowserHelper.removePWATokenFromIoTPlatform();
            }

            this.updateAndSavePreferences();
        }
    }

    askUserTheNotificationPermission() {
        this.setState({
            needToAskUserForNotificationPermission: true
        })
    }

    afterAskingTheUserForNotificationPermissionCallback() {
        this.setState({
            needToAskUserForNotificationPermission: false
        })
    }

    updateAndSavePreferences() {
        const {
            preferences,
            currentCrmPreferences,
            prefToSave,
            updateCrm
        } = this.state;

        return this.savePreferences(prefToSave, preferences, currentCrmPreferences, updateCrm);
    }



    resetAndSavePreferences() {
        const {
            preferences,
            currentCrmPreferences,
            prefToSave,
            updateCrm
        } = this.state;

        const resetPreferences = preferences.map(thing => {
            if (ThingsHelper.isAPushNotificationThing(thing)) {
                return {
                    ...thing,
                    data: flagStatus.REFUSED
                };
            } else {
                return thing;
            }
        });

        const resetCrmPreferences = {
            ...currentCrmPreferences,
            userOptedInForPushNotifications: false,
            userOptedInForSMS: false,
        }

        this.setState({
            prefToSave: null,
            updateCrm: false,
            preferences: resetPreferences
        });

        return this.savePreferences(null, resetPreferences, resetCrmPreferences, false, true);
    }

    savePreferences(prefToSave, preferences, currentCrmPreferences, updateCrm, bothPrefsAndCrm){
        const {
            userPin,
            setThingsAsync,
            setUserPreferencesAsync
        } = this.props;

        this.setState({ spinnerLoading: true })

        debug("[MANAGE NOTIFICATIONS] start savePreferences ", preferences, 
        " currentCrmPreferences ", currentCrmPreferences,
        " updateCrm: ", updateCrm)

        let things = null
        /**
         * The idea is:
         *  - if I have a single prefrences set, we update that single thing
         *  - if we need to update the stuff related to CRM, we do not update things except for MANAGENOTIFICATION
         *  - else we update everything as before
         */
        if(!isNil(prefToSave)){
            things = ThingsHelper.distinctPush([prefToSave], { type: thingVuseProperties.MANAGENOTIFICATION, data: flagStatus.ACCEPTED });
        } else if(updateCrm) {
            things = ThingsHelper.distinctPush([], { type: thingVuseProperties.MANAGENOTIFICATION, data: flagStatus.ACCEPTED });
        } else {
            things = ThingsHelper.distinctPush(preferences, { type: thingVuseProperties.MANAGENOTIFICATION, data: flagStatus.ACCEPTED });
        }

        //set preferences on iot platform
        const tenantUserId = Commons.generateTenantUserId(userPin);
        return setThingsAsync(
            things,
            tenantUserId,
            getVuseUuid(),
            CUSTOMER
        ).then((response) => {
            if (response) {
                setDoneNotificationPreferences(true);
                this.setState({prefToSave: null});
                logThingsInAnalyticsEvent(
                    AnalyticsEvents.SAVE_NOTIFICATION_PREFERENCES,
                    this.getAvailablePreferences(things)
                )
                // logAnalyticsEvent(AnalyticsEvents.EMAIL, {
                //     status: getAnalyticStatusBy(currentCrmPreferences.userOptedInForEmails)
                // })
                //logAnalyticsEvent(AnalyticsEvents.IN_APP, {
                //    status: getAnalyticStatusBy(currentCrmPreferences.userOptedInForPushNotifications)
                // }) --> old

                //hanlde SUGGESTION notifications
                const suggestionsThing = things.find(thing => thing.type === thingVuseProperties.SUGGESTIONS);
                if (suggestionsThing?.data === flagStatus.ACCEPTED) {
                    Notifications.handleSuggestionNotifications();
                } else {
                    Notifications.removeAllSuggestionNotifications();
                }

                console.debug("setUserPreferencesAsync", currentCrmPreferences);

                //set preferences on crm
                if(updateCrm || bothPrefsAndCrm){
                    return setUserPreferencesAsync(currentCrmPreferences).then(() => {
                        this.setState({
                            currentCrmPreferences: currentCrmPreferences,
                            crmPreferences: currentCrmPreferences,
                            oldPreferences: preferences,
                            preferences: preferences,
                            updateCrm: false
                        });

                        Promise.all([
                            // new Promise((resolve, reject) => {
                            //     store.dispatch(userActions.setUserSettings(
                            //         crmUserSettings.OPT_IN_EMAIL,
                            //         currentCrmPreferences.userOptedInForEmails,
                            //         resolve,
                            //         reject
                            //     ))
                            // }),

                            new Promise((resolve, reject) => {
                                store.dispatch(userActions.setUserSettings(
                                    crmUserSettings.OPT_IN_NOTIFICATIONS,
                                    currentCrmPreferences.userOptedInForPushNotifications,
                                    resolve,
                                    reject
                                ))
                            })
                        ])
                        .then(() => {
                            this.setState({ spinnerLoading: false })
                        })
                        .catch(() => {
                            this.setState({ spinnerError: true });
                        })
                    })
                } else {
                    this.setState({ spinnerLoading: false });
                }
            } else {
                return Promise.reject("setThings return zero results " + response);
            }
        }).catch((error) => {
            debug("ERROR: [MANAGE NOTIFICATIONS] savePreferences ", error)
            // store.dispatch(commonsActions.showErrorAlert(true));
            this.setState({ spinnerError: true })
        });
    }
    

    getAvailablePreferences(preferences) {
        const {
            options = {}
        } = this.state;

        const optionValues = Object.values(options)
            .flat()
            .filter(option => !!option && !!this.getAEMComponent(option.label));

        return preferences.filter(preference => optionValues.findIndex(option => option.command === preference.type) > -1);
    }

    _getAEMComponent(key) {
        return this.page?.getComponent(aemPages.NOTIFICATION_PREFERENCES_MENU, key);
    }


    _handleBack() {
        history.goBack();
    }


    _setCrmMarketingPreference(preference, value) {
        const { currentCrmPreferences } = this.state;
        this.setState({
            currentCrmPreferences: {
                ...currentCrmPreferences,
                [preference]: value
            },
            updateCrm: true
        },
        () => this.handleSavePreferences())
    }

    _setNotifPermissionActivePanelType(type) {
        this.setState({notifPermissionActivePanelType: type})
    }


    render() {

        const {
            options,
            preferences,
            crmPreferences,
            isOpenContactPanel,
            currentCrmPreferences,
            isLoading
        } = this.state;

        const { isUpdatingThingsToIoTPlatform } = this.props;

        return (
            <div className="page w-100">
                <Header 
                    rightButton={{
                        icon: <span className="bat-icon-close" />,
                        onClick: this.handleBack
                }}>
                    {this.dictionary.NOTIF_PREF_HEADER}
                </Header>
                <div className="notification-wrapper">
                    <div className="notification-preferences-wrapper">

                        <NotificationOptions options={options}
                            getAEMComponent={this.getAEMComponent}
                            updatePreference={this.updatePreference}
                            preferences={preferences}
                        />

                    </div>

                    {
                        !Tenants.isCanadaDark() &&

                        <div className="marketing-notification-preferences">
                            {/*Tenants.isGlobal() &&
                            <>
                            <div className="notification-preferences-label d-block mb-5 mt-4">
                                MARKETING INFORMATION
                            </div>
                            <div className="marketing-content mb-5">
                                I would like to receive marketing information about products and services relating to Vuse and all other current and future brands of British American tobacco UK Limited and Nicoventures Retail (UK) Limited.
                            </div>
                            </>
                            */}

                            <div className="switch-container no-margin-top">
                                <label className="form-check-label" htmlFor="userOptedInForPushNotifications">
                                    {this.dictionary.NOTIF_PREF_MKT_NOTIFICATIONS_TITLE}
                                </label>
                                <div className="form-check form-switch">
                                    <input className="form-check-input"
                                        type="checkbox"
                                        checked={crmPreferences.userOptedInForPushNotifications}
                                        value={crmPreferences.userOptedInForPushNotifications}
                                        id="userOptedInForPushNotifications"
                                        name="userOptedInForPushNotifications"
                                        onChange={(event) => {
                                            this.setCrmMarketingPreference('userOptedInForPushNotifications', event.target.checked);
                                            logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
                                                field_name: AnalyticsFieldNames.NOTIFICATIONS,
                                                field_value: !crmPreferences.userOptedInForPushNotifications
                                            });
                                        }} />
                                    <label className="form-check-label" htmlFor="userOptedInForPushNotifications"></label>
                                </div>
                            </div>
                            <div>
                                <div className="marketing-content" onClick={() => !Tenants.isSouthAfrica() ? history.push(buildURI(routingConstants.CONTACT) + convertObjectToQueryString({ canHandleBack: true }), {type: 'slide', backTo: routingConstants.MANAGE_NOTIFICATIONS}) : null}>
                                    {this.dictionary.NOTIF_PREF_MKT_INFO_CONTENT}
                                </div>
                                <div className="marketing-content footer" onClick={() => history.push(buildURI(routingConstants.PRIVACY_POLICY) + convertObjectToQueryString({ canHandleBack: true }), {type: 'slide', backTo: routingConstants.MANAGE_NOTIFICATIONS})}>
                                    {this.dictionary.NOTIF_PREF_PRIVACY_LINK}
                                </div>
                            </div>
                        </div>
                    }

                </div>
                <div className="page-linear-gradient-bg"></div>
                <ContactPanel isOpen={isOpenContactPanel} onClose={() => this.setState({ isOpenContactPanel: false })} />
                <NotificationPermissionHandler
                    needToAskUserForNotificationPermission={this.state.needToAskUserForNotificationPermission}
                    afterAskingTheUserForNotificationPermissionCallback={this.afterAskingTheUserForNotificationPermissionCallback.bind(this)}
                    askAfterUserInteraction={true}
                    updateAndSavePreferences={this.updateAndSavePreferences.bind(this)}
                    resetAndSavePreferences={this.resetAndSavePreferences.bind(this)}
                    showLoader={false}
                    notifPermissionActivePanelType={this.state.notifPermissionActivePanelType}
                />

                {/*isLoading && <CustomLoaderOverlay />*/}

                <SpinnerModal
                    show={isUpdatingThingsToIoTPlatform || this.state.spinnerLoading || isLoading}
                    error={this.state.spinnerError}
                    onForceClose={() => {
                        const tokens = AxiosCancelTokensHolder.get()

                        tokens.forEach(token => {
                            token.abort()
                        })
                    }}
                />
                
                <NotificationPermissions
                    title={this.dictionary.NOTIF_PREF_HEADER}
                    from={routingConstants.NOTIFICATION_PREFERENCES}
                    onClose={this.handleBack}
                    setNotifPermissionActivePanelType={this.setNotifPermissionActivePanelType}
                />

            </div>
        );

    }
}

function mapStateToProps(state) {

    return {
        userPin: state.onboardingReducer.userPin,
        isUpdatingThingsToIoTPlatform: state.commonsReducer.isUpdatingThingsToIoTPlatform
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setThingsAsync: (flags, tenantUserId, uuid, vendor) => {
            return new Promise((resolve, reject) => {
                dispatch(commonsActions.setThings(flags, tenantUserId, uuid, vendor, resolve, reject));
            })
        },
        getUserInfoAsync: (userId) => {
            return new Promise((resolve, reject) => {
                dispatch(userActions.getUserInfo(userId, resolve, reject))
            })
        },
        setUserPreferencesAsync: (data) => {
            return new Promise((resolve, reject) => {
                dispatch(userActions.updateUserInfo(data, resolve, reject))
            })
        },
    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)((ManageNotifications));
export { connectedComponent as ManageNotifications };