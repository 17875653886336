import React, { useCallback, useEffect, useState } from 'react';
import BrowserHelper from '../../_helpers/browser/browser.helper';
import { debug } from '../../_helpers/debug';
import { PermissionsHelper } from '../../_helpers/permissions/permissions.helper';
import {
  NotificationPermissionPanel,
  NOTIFICATION_PERMISSION_PANEL_TYPES,
} from './NotificationPermissionPanel';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { AnalyticsEvents, PERMISSION_STATUS } from '../../_constants';
import { CustomLoaderOverlay } from '../CustomLoaderOverlay';

/**
 * 
 * @param {
 *  needToAskUserForNotificationPermission - true if the user asks to myVuse the push notification permission, false otherwise
 *  afterAskingTheUserForNotificationPermissionCallback - callback called when the flow of asking permission to user is finished
 *  askAfterUserInteraction - it is true if the user is asked for permission by doing an interaction (click of the button), false otherwise
 *  updateAndSavePreferences - It a promise triggered when everything goes fine and the user granted permissions
 *  resetAndSavePreferences - It a promise triggered when something goes wrong or the user has denied some permission
 * } props 
 * @returns 
 */
export const NotificationPermissionHandler = (props) => {
  const {
    needToAskUserForNotificationPermission = false,
    afterAskingTheUserForNotificationPermissionCallback = () => {},
    askAfterUserInteraction = false,
    updateAndSavePreferences = () => new Promise((resolve) => resolve),
    resetAndSavePreferences = () => new Promise((resolve) => resolve),
    notifPermissionActivePanelType= null,
    overrideBottom = false,
    showLoader = false
  } = props;

  const [
    notificationPermissionActivePanelType,
    setNotificationPermissionActivePanelType,
  ] = useState(notifPermissionActivePanelType);

  useEffect(() => {
    if(notifPermissionActivePanelType!==null){
      setNotificationPermissionActivePanelType(notifPermissionActivePanelType);
    }
  }, [notifPermissionActivePanelType]);

  const [isLoading, setIsLoading] = useState(false)

  // it's the callback when the user changes to true the needToAskUserForNotificationPermission params
  const askUserTheNotificationPermission = useCallback(() => {
    // if the browser is the Nuviu Browser and it has to have specific methods to ask the user the notification permissions
    if (BrowserHelper.isBLEBrowser()) {
      setIsLoading(true)
      // if the user has not made any choice yet, ask the user via prompt message the OS notification permission
      // if the user has granted or denied the OS notification permission than return the choice it has been made
      debug(`[Notification]NotificationPermissionHandler`);
      return BrowserHelper.checkOSNotificationPermissionStatus().then(async ({ state: osPermissionStatus }) => {
        // osPermissionStatus is the OS notification permission status
        // alert(osPermissionStatus);
        debug(`[Notification]checkOSNotificationPermissionStatus `, osPermissionStatus);
        if (PermissionsHelper.hasGrantedStatus(osPermissionStatus)) {
          // if the user has granted the OS notification permission than
          // check if the user has the PWA notification permission  
          return BrowserHelper.checkPWANotificationPermissionStatus().then(async ({ state: browserPermissionStatus }) => {
            // browserPermissionStatus is the PWA notification permission status 
            // if the user has not made a choice yet (browserPermissionStatus === 'default')
            // or It has already granted the PWA notification permission (browserPermissionStatus === 'granted')
            // or it has denied the PWA notification permission (browserPermissionStatus === 'denied') and now it's trying to change the notification preferences
            debug(`[Notification]checkPWANotificationPermissionStatus `, browserPermissionStatus);
            // alert(browserPermissionStatus);
            if (
              PermissionsHelper.hasDefaultStatus(browserPermissionStatus) ||
              //PermissionsHelper.hasGrantedStatus(browserPermissionStatus) || 
              (askAfterUserInteraction && PermissionsHelper.hasDeniedStatus(browserPermissionStatus))
            ) {
              // Calling the following method, except when the user has already granted the PWA notification permissions,
              // it will open a notifications dialog asking the user for PWA push notification permission 
              return BrowserHelper.enablePWANotificationPermission()
                .then(async ({ state: newBrowserPermissionStatus }) => {
                  debug(`[Notification]enablePWANotificationPermission `, newBrowserPermissionStatus);
                  // newBrowserPermissionStatus is the PWA notification permission status 
                  // if the user has granted the PWA notification permission
                  if (PermissionsHelper.hasGrantedStatus(newBrowserPermissionStatus)) {

                    // if the user has changed the PWA notification permission to granted 
                    if (browserPermissionStatus !== newBrowserPermissionStatus) {
                      logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_PWA_PERMISSION, {
                        'status': PERMISSION_STATUS.GRANTED
                    })

                      debug(`[Nuviu] the user has granted PWA notification permission!!`);
                    }

                    if (askAfterUserInteraction) {
                      // if the user asked the notification permission after it made some changes (eg. change notification preferences)
                      // than save the new notification preferences
                      debug(`[Notification]updateAndSavePreferences`);
                      await updateAndSavePreferences();
                    } // otherwise don't do anything
                    setIsLoading(false)

                    afterAskingTheUserForNotificationPermissionCallback();
                  } else {
                    if (PermissionsHelper.hasDeniedStatus(newBrowserPermissionStatus)) {
                        // if the user has changed the PWA notification permission to granted 
                        if (browserPermissionStatus !== newBrowserPermissionStatus) {
                          logAnalyticsEvent(AnalyticsEvents.NOTIFICATION_PWA_PERMISSION, {
                            'status': PERMISSION_STATUS.DENIED
                        })
                      }
                    }
                    setIsLoading(false)
                    // it has shown to user a warning message dialog
                    setNotificationPermissionActivePanelType(
                      NOTIFICATION_PERMISSION_PANEL_TYPES.WARNING
                    );
                  }
                })
                .catch(() => {
                  setIsLoading(false)
                  // it has shown to user a error message dialog
                  setNotificationPermissionActivePanelType(
                    NOTIFICATION_PERMISSION_PANEL_TYPES.ERROR
                  );
                });
            } else if (!askAfterUserInteraction && PermissionsHelper.hasDeniedStatus(browserPermissionStatus)) {
              // else if the user has denied the PWA notification permission and now it's NOT changing any notification preferences
              // (eg. landing on the dashboard)
              // than toggle off all notification preferences and save them
              await resetAndSavePreferences();
            } else if (askAfterUserInteraction) {
              // else if the user has asked for notification permission 
              await updateAndSavePreferences();
            }

            setIsLoading(false)
            afterAskingTheUserForNotificationPermissionCallback();
          });
        } else if (askAfterUserInteraction) {
          setIsLoading(false)
          // if the user has denied the OS notification permission after an interaction (eg. save new preferences) 
          // it will be shown a warning panel
          setNotificationPermissionActivePanelType(
            NOTIFICATION_PERMISSION_PANEL_TYPES.MISSING_OS_NOTIFICATION_PERMISSION
          );
        } else {
          // in any other case, toggle off al notification-preferences and save them.
          await resetAndSavePreferences()
          afterAskingTheUserForNotificationPermissionCallback();
        }

        return osPermissionStatus;
      }).catch((error) => {
        setIsLoading(false)
        afterAskingTheUserForNotificationPermissionCallback();
        debug("ERROR: [askUserTheNotificationPermission] ", error);
        return Promise.reject(error);
      })
    } else {
      // in any other browser
      // it's like the user has granted the notification permission
      return updateAndSavePreferences().then(() => {
        afterAskingTheUserForNotificationPermissionCallback();
      });
    }
  }, [askAfterUserInteraction, afterAskingTheUserForNotificationPermissionCallback, updateAndSavePreferences, resetAndSavePreferences])

  useEffect(() => {
    if (needToAskUserForNotificationPermission) {
      // ask the user the notification permission only if needToAskUserForNotificationPermission is set to true
      askUserTheNotificationPermission()
    }
  }, [needToAskUserForNotificationPermission]);

  return (
    <>
      <NotificationPermissionPanel
        type={notificationPermissionActivePanelType}
        onClose={() => {
          // close the panel
          setNotificationPermissionActivePanelType(null);

          // trigger the callback after closing the panel
          afterAskingTheUserForNotificationPermissionCallback();
        }}
        onContinue={() => {
          // when the user press on continue button it means that it doesn't make any change
          // toggle off the notification preferences and save them
          return resetAndSavePreferences();
        }}
        onTryAgain={() => {
          // when the user press on try again button it means that it makes some changes
          // trigger again the askUserTheNotificationPermission method
          askUserTheNotificationPermission()
        }}
        overrideBottom={overrideBottom}
      />
      {showLoader && isLoading && <CustomLoaderOverlay />}
    </>
  );
};
