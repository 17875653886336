import cx from "classnames";
import _, { isEmpty, isNil } from "lodash";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import {
  commonsActions,
  getVuseUuid,
  tipsActions,
  userActions,
} from "../../_actions";
import { getDeviceSpec } from "../../_actions/appConfig.actions";
import {
  BatteryRemindersOptions,
  CustomSlidingPanel,
  Header,
  PickerPanel,
  SpinnerModal,
} from "../../_components";
import {
  AnalyticsEvents,
  AnalyticsFieldNames,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  CUSTOMER,
  MODE_BALANCED,
  PROFILE_EPOD2,
  PROFILE_WAWE2,
  VUSE_DEVICE,
  aemPages,
  crmUserSettings,
  routingConstants,
  thingDeviceProperties,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyHeadingDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import {
  getAnalyticStatusBy,
  logAnalyticsEvent,
  logAnalyticsEventForDevice,
} from "../../_helpers/analytics/logAnalytics";
import { Commons } from "../../_helpers/commons";
import { debug } from "../../_helpers/debug";
import {
  getDeviceThingFromSN,
  isBatterySaverSupported,
  isDeviceConnected,
  isLowPowerModeEnabledBy,
  updateCloudConfig,
  updateDeviceInReducer,
} from "../../_helpers/device";
import { Utils } from "../../_helpers/graphic/utils";
import { history } from "../../_helpers/history";
import {
  buildURI,
  handleUIDeviceDisconnection,
} from "../../_helpers/navigation";

class BatterySettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDevice: null,
      deviceSpec: null,
      lowPowerMode: false,
      isBatterySaverSupported: false,
      isOpenCheckBatteryPanel: false,
      isOpenBatterySaverPanel: false,
      isActiveLowBatteryAlert: false,
      isActiveBatterySaver: false,
      isActiveCheckBatteryAlert: false,
      isActiveFullBatteryAlert: false,
      defaultPerc: "15%",
      defaultPercBS: "20%",
      percSelected: { currentPerc: "15%" },
      percSelectedBS: { currentPerc: "20%" },
      timeSelected: {
        hour: "12",
        min: "57",
        meridiem: "AM",
      },
      defaultTime: {
        hour: "12",
        min: "57",
        meridiem: "AM",
      },
      percOptions: {
        currentPerc: _.map([...Array(19)], (_, idx) => `${(idx + 1) * 5}%`),
      },
      batterySaverDisableAlertIsOpen: false,
      spinnerLoading: false,
      spinnerError: false
    };

    this.goToTutorial = this._goToTutorial.bind(this);
    this.goToDashboard = this._goToDashboard.bind(this);
    this.handleSetLowPowerMode = this._handleSetLowPowerMode.bind(this);

    this.saveNewDefaultPercentage = this._saveNewDefaultPercentage.bind(this);
    this.saveNewDefaultTime = this._saveNewDefaultTime.bind(this);

    this.toggleLowBatteryAlert = this._toggleLowBatteryAlert.bind(this);
    this.toggleBatterySaverAlert = this._toggleBatterySaverAlert.bind(this);
    this.toggleCheckBatteryAlert = this._toggleCheckBatteryAlert.bind(this);
    this.toggleFullBatteryAlert = this._toggleFullBatteryAlert.bind(this);

    this.updateProperty = this._updateProperty.bind(this);
    this.loadBatteryThings = this._loadBatteryThings.bind(this);
    this.loadDeviceThing = this._loadDeviceThing.bind(this);

    this.handleSaveBatterySaverPreferences =
      this._handleSaveBatterySaverPreferences.bind(this);
    this.toggleLowPowerMode = this._toggleLowPowerMode.bind(this);
    this.handleDisableBatterySaver = this._handleDisableBatterySaver.bind(this);
    this.handleCancelBatterySaver = this._handleCancelBatterySaver.bind(this);

    this.aem = new AEMHelper();
    this.dictionary = this.aem.getDictionary(aemPages.BATTERY_SETTINGS, {
      BATT_PREF_HEADER: propertyHeadingDefaultEmpty,
      BATT_PREF_FULL_BATTERY_ALERT_BODY: propertyTextDefaultEmpty,
      BATT_PREF_LOW_POWER_MODE: propertyTextDefaultEmpty,
      BATT_PREF_LOW_BATTERY_ALERT_BODY: propertyTextDefaultEmpty,
      BATT_PREF_CHECK_BATTERY_BODY: propertyTextDefaultEmpty,
      BATT_PREF_SAVE_BTN_CHOOSE: propertyCtaItemsDefaultEmpty,
      BATT_PREF_BATTERY_ALERT_SUBHEADER: propertyTextDefaultEmpty,
      BATT_PREF_CHECK_BATTERY_TITLE: propertyTextDefaultEmpty,
      BATT_PREF_RECHARGE_REMINDERS_TITLE: propertyTextDefaultEmpty,
      BATT_PREF_FULL_BATTERY_ALERT_TITLE: propertyTextDefaultEmpty,
      BATT_PREF_LOW_BATTERY_ALERT_TITLE: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_ALERT_HEADER: propertyHeadingDefaultEmpty,
      BATT_PREF_LOW_BATTERY_ALERT_SUBHEADER: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_RECHARGE_CONTENT: propertyTextDefaultEmpty,
      BATT_PREF_LOW_BATTERY_ALERT_HEADER: propertyHeadingDefaultEmpty,
      BATT_PREF_LOW_POWER_MODE_TITLE: propertyTextDefaultEmpty,
      BATT_PREF_LOW_POWER_MODE_CONTENT: propertyTextDefaultEmpty,
      BATT_PREF_LOW_POWER_MODE_CONTENT_NO_BS: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_TITLE: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_BODY: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_HEADER: propertyHeadingDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_SUBHEADER: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_ON_CONTENT: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_ON_SUBCONTENT: propertyTextDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_BTN_DISABLE: propertyCtaItemsDefaultEmpty,
      BATT_PREF_BATTERY_SAVER_BTN_CANCEL: propertyCtaItemsDefaultEmpty,
    });
  }

  componentDidMount() {
    document.body.classList.add("battery-settings");



    /* load device and device specifics */
    const currentDevice = this.props.devices.find((device) => device.selected);
    handleUIDeviceDisconnection(currentDevice);

    /* get from reducer current customer thing*/
    this.loadBatteryThings(currentDevice);

    this.loadDeviceThing(currentDevice);

    const deviceProfile =
      currentDevice?.deviceType === PROFILE_EPOD2
        ? PROFILE_WAWE2
        : currentDevice?.deviceType;
    const deviceSpec = getDeviceSpec(deviceProfile);

    this.setState({
      currentDevice,
      deviceSpec,
      lowPowerMode: isLowPowerModeEnabledBy(currentDevice, deviceSpec),
      isActiveBatterySaver:
        currentDevice?.cloudInfo?.batterySavingEnabled === 1,
    });

    this.checkIsBatterySaverSupported(currentDevice);

    /* Load most updated customer thing */
    const { userPin, getThings } = this.props;

    const tenantUserId = Commons.generateTenantUserId(userPin);
    getThings(tenantUserId.toString(), getVuseUuid(), CUSTOMER);

    this.props.setUserSettings(crmUserSettings.BATTERY_SAVER_USED, "true");
  }

  componentWillUnmount() {
    document.body.classList.remove("battery-settings");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.devices !== this.props.devices) {
      // If device is no more present, redirect to homepage
      const device = this.props.devices.find((device) => device.selected);
      // handleUIDeviceDisconnection(device);

      if (!isDeviceConnected(device)) {
        return this.goToDashboard();
      }

      //if something in current device changed update it and recalculate if it's in low power mode
      if (!_.isEqual(device, this.state.currentDevice)) {
        const isInLowPowerMode = isLowPowerModeEnabledBy(
          device,
          this.state.deviceSpec
        );
        const wasInLowerPowerMode = isLowPowerModeEnabledBy(
          prevProps.devices.find((device) => device.selected),
          this.state.deviceSpec
        );

        console.debug(
          "isLowPowerModeEnabledBy",
          isInLowPowerMode,
          wasInLowerPowerMode
        );

        /**
         * Send event only if "low power mode" property is changed, not every time
         * something in the device changes
         */
        if (wasInLowerPowerMode !== isInLowPowerMode) {
          logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {
            low_power_mode: getAnalyticStatusBy(isInLowPowerMode) === 'enabled' ? true : false,
          });
        }

        this.setState({
          currentDevice: device,
          lowPowerMode: isInLowPowerMode,
        });
      }

      this.loadBatteryThings(device);
    }

    // if (prevProps.things !== this.props.things) {
    //   this.loadBatteryThings(device);
    // }
  }

  _loadBatteryThings(device) {
    const customerThing = Commons.getCustomerThing();
    const deviceThing = Commons.getDeviceThing(device?.serialNumber);

    let defaultPerc = this.state.defaultPerc;
    let isActiveLowBatteryAlert = this.state.isActiveLowBatteryAlert;
    let isActiveCheckBatteryAlert = this.state.isActiveCheckBatteryAlert;
    let isActiveFullBatteryAlert = this.state.isActiveFullBatteryAlert;
    let defaultTime = this.state.defaultTime;

    /* We read in first place from the custom for back-compatibility, then we read from the device and, if present, we use those one */
    if (customerThing?.properties) {
      const newProperties = _.keyBy(customerThing.properties, (o) => o.type);

      defaultPerc =
        newProperties[thingVuseProperties.BATTERY_THRESHOLD]?.data !==
          "false" &&
        newProperties[thingVuseProperties.BATTERY_THRESHOLD]?.data !== false &&
        newProperties[thingVuseProperties.BATTERY_THRESHOLD]?.data !== null &&
        newProperties[thingVuseProperties.BATTERY_THRESHOLD]?.data !== "" &&
        newProperties[thingVuseProperties.BATTERY_THRESHOLD]?.data !== undefined
          ? newProperties[thingVuseProperties.BATTERY_THRESHOLD]?.data + "%"
          : defaultPerc;

      isActiveLowBatteryAlert =
        newProperties[thingVuseProperties.BATTERY_THRESHOLD_ENABLED]?.data ===
          "true" ||
        newProperties[thingVuseProperties.BATTERY_THRESHOLD_ENABLED]?.data ===
          true;
      isActiveCheckBatteryAlert =
        newProperties[thingVuseProperties.BATTERY_FORECAST_ENABLED]?.data ===
          "true" ||
        newProperties[thingVuseProperties.BATTERY_FORECAST_ENABLED]?.data ===
          true;
      isActiveFullBatteryAlert =
        newProperties[thingVuseProperties.FULL_CHARGE_ALERT]?.data === "true" ||
        newProperties[thingVuseProperties.FULL_CHARGE_ALERT]?.data === true;

      console.log(
        "newProperties[thingVuseProperties.SELECTED_TIME]?.data",
        newProperties[thingVuseProperties.SELECTED_TIME]?.data
      );
      console.log(
        "moment(newProperties[thingVuseProperties.SELECTED_TIME]?.data).isValid()",
        moment(newProperties[thingVuseProperties.SELECTED_TIME]?.data).isValid()
      );
      defaultTime =
        newProperties[thingVuseProperties.SELECTED_TIME]?.data &&
        moment(newProperties[thingVuseProperties.SELECTED_TIME]?.data).isValid()
          ? {
              hour: moment(
                newProperties[thingVuseProperties.SELECTED_TIME].data
              ).format("hh"),
              min: moment(
                newProperties[thingVuseProperties.SELECTED_TIME].data
              ).format("mm"),
              meridiem: moment(
                newProperties[thingVuseProperties.SELECTED_TIME]?.data
              ).format("A"),
            }
          : {
              hour: "03",
              min: "15",
              meridiem: "PM",
            };
    }

    /* Check if the props of the battery are set per device, in case updated them */
    if (deviceThing?.properties) {
      const newProperties = _.keyBy(deviceThing.properties, (o) => o.type);
      if(!isNil(newProperties[thingDeviceProperties.BATTERY_THRESHOLD]?.data)) {
        defaultPerc = newProperties[thingDeviceProperties.BATTERY_THRESHOLD]?.data + "%";
      }

      if(!isNil(newProperties[thingDeviceProperties.BATTERY_THRESHOLD_ENABLED]?.data)) {
        isActiveLowBatteryAlert = newProperties[thingDeviceProperties.BATTERY_THRESHOLD_ENABLED]?.data === 'true'
          || newProperties[thingDeviceProperties.BATTERY_THRESHOLD_ENABLED]?.data === true
      }

      if(!isNil(newProperties[thingDeviceProperties.BATTERY_FORECAST_ENABLED]?.data)) {
        isActiveCheckBatteryAlert = newProperties[thingDeviceProperties.BATTERY_FORECAST_ENABLED]?.data === 'true'
          || newProperties[thingDeviceProperties.BATTERY_FORECAST_ENABLED]?.data === true
      }

      if(!isNil(newProperties[thingDeviceProperties.FULL_CHARGE_ALERT]?.data)) {
        isActiveFullBatteryAlert = newProperties[thingDeviceProperties.FULL_CHARGE_ALERT]?.data === 'true'
          || newProperties[thingDeviceProperties.FULL_CHARGE_ALERT]?.data === true
      }

      if(!isNil(newProperties[thingDeviceProperties.SELECTED_TIME]?.data) && !isEmpty(newProperties[thingDeviceProperties.SELECTED_TIME]?.data)
          && moment(newProperties[thingDeviceProperties.SELECTED_TIME]?.data).isValid()) {
        defaultTime = {
          hour: moment(
            newProperties[thingDeviceProperties.SELECTED_TIME].data
          ).format("hh"),
          min: moment(
            newProperties[thingDeviceProperties.SELECTED_TIME].data
          ).format("mm"),
          meridiem: moment(
            newProperties[thingDeviceProperties.SELECTED_TIME]?.data
          ).format("A"),
        }
      }
    }

    this.setState(
      {
        timeSelected: defaultTime,
        defaultTime: defaultTime,
        isActiveFullBatteryAlert: isActiveFullBatteryAlert,
        defaultPerc: defaultPerc,
        isActiveCheckBatteryAlert: isActiveCheckBatteryAlert,
        isActiveLowBatteryAlert: isActiveLowBatteryAlert,
        percSelected: { currentPerc: defaultPerc },
      },
      () => {
        console.log("this.state.timeSelected", this.state.timeSelected);
        console.log("this.state.defaultTime", this.state.defaultTime);
        console.log(
          "this.state.isActiveFullBatteryAlert",
          this.state.isActiveFullBatteryAlert
        );
        console.log("this.state.defaultPerc", this.state.defaultPerc);
        console.log(
          "this.state.isActiveCheckBatteryAlert",
          this.state.isActiveCheckBatteryAlert
        );
        console.log(
          "this.state.isActiveLowBatteryAlert",
          this.state.isActiveLowBatteryAlert
        );
        console.log("this.state.percSelected", this.state.percSelected);
      }
    );
  }

  _loadDeviceThing(device) {
    const deviceThing = Commons.getDeviceThing(device?.serialNumber);
    const cloudInfo = device?.cloudInfo;

    console.debug("loadDeviceThing", deviceThing, cloudInfo);

    if (deviceThing?.properties) {
      let defaultPercBS = this.state.defaultPercBS;
      let isActiveBatterySaver = this.state.isActiveBatterySaver;

      const newProperties = _.keyBy(deviceThing.properties, (o) => o.type);

      defaultPercBS =
        newProperties[thingDeviceProperties.BATTERY_SAVER_THRESHOLD]?.data !==
          "false" &&
        newProperties[thingDeviceProperties.BATTERY_SAVER_THRESHOLD]?.data !==
          false &&
        newProperties[thingDeviceProperties.BATTERY_SAVER_THRESHOLD]?.data !==
          null &&
        newProperties[thingDeviceProperties.BATTERY_SAVER_THRESHOLD]?.data !==
          "" &&
        newProperties[thingDeviceProperties.BATTERY_SAVER_THRESHOLD]?.data !==
          undefined
          ? newProperties[thingDeviceProperties.BATTERY_SAVER_THRESHOLD]?.data +
            "%"
          : cloudInfo?.batterySavingThresholdValue
          ? cloudInfo.batterySavingThresholdValue + "%"
          : defaultPercBS;

      isActiveBatterySaver =
        newProperties[thingDeviceProperties.BATTERY_SAVER_ENABLED]?.data ===
          "true" ||
        newProperties[thingDeviceProperties.BATTERY_SAVER_ENABLED]?.data ===
          true;

      this.setState({
        defaultPercBS,
        isActiveBatterySaver,
        percSelectedBS: { currentPerc: defaultPercBS },
      });
    }
  }

  _updateProperty(propertyType, propertyData, vendor = CUSTOMER, onSuccess = null, onFailure = null) {
    const { userPin, setThings } = this.props;

    const payload = { type: propertyType, data: propertyData };

    const tenantUserId = Commons.generateTenantUserId(userPin);

    let uuid = getVuseUuid();

    if (vendor === VUSE_DEVICE) {
      const deviceThing = getDeviceThingFromSN(
        this.state.currentDevice?.serialNumber
      );
      uuid = deviceThing?.uuid;
    }

    setThings([payload], tenantUserId, uuid, vendor, onSuccess, onFailure);
  }

  _goToTutorial() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.TUTORIAL});
    history.push(buildURI(routingConstants.BATTERY_SETTINGS_TUTORIAL), {type: 'slide'});
  }

  _goToDashboard() {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOSE});
    history.push(buildURI(routingConstants.DASHBOARD));
  }

  _toggleLowPowerMode() {
    const { currentDevice, deviceSpec, lowPowerMode } = this.state;
    let powerLevel = null;

    this.setState({ spinnerLoading: true })
    
    console.debug("LOW_POWER_MODE", lowPowerMode);

    if (!lowPowerMode) {
      updateDeviceInReducer(
        currentDevice?.serialNumber,
        {
          prevPowerLevel: currentDevice?.cloudInfo?.powerLevel,
        },
        false
      );

      powerLevel = parseInt(deviceSpec?.lowPower?.value, 10);
    } else {
      const balancedMode = deviceSpec?.cloudSize.find(
        (cl) => cl.type === MODE_BALANCED
      );

      powerLevel =
        currentDevice?.prevPowerLevel || parseInt(balancedMode?.value, 10);
    }

    // // debug(
    // //   "[handleSetLowPowerMode] isCurrentlyOnLowPowerMode: ",
    // //   isCurrentlyOnLowPowerMode
    // // );
    // // debug("[handleSetLowPowerMode] setting newValue: ", newValue);

    updateCloudConfig(currentDevice, {
      powerLevel,
    })
    .then(() => {
      this.setState({
        ...this.state,
        lowPowerMode: !lowPowerMode,
      });

      this.setState({ spinnerLoading: false });
    })
    .catch(() => {
      this.setState({ spinnerError: true });
    })
  }

  _handleSetLowPowerMode() {
    const { currentDevice, isBatterySaverSupported } = this.state;

    if (
      isBatterySaverSupported &&
      currentDevice?.cloudInfo?.batterySavingEnabled === 1 &&
      currentDevice?.cloudInfo?.batterySavingOn === 1
    ) {
      this.setState({
        batterySaverDisableAlertIsOpen: true,
      });
    } else {
      this.toggleLowPowerMode();
    }
  }

  _saveNewDefaultPercentage(currentPerc) {
    this.setState({ spinnerLoading: true })

    this.setState({ defaultPerc: currentPerc });
    
    this.updateProperty(
      thingDeviceProperties.BATTERY_THRESHOLD,
      parseInt(currentPerc.trim().slice(0, -1), 10),
      VUSE_DEVICE,
      () => this.setState({ spinnerLoading: false }),
      () => this.setState({ spinnerError: true })
    );
  }

  _saveNewDefaultTime(timeSelected) {
    this.setState({ spinnerLoading: true })

    this.setState({ defaultTime: timeSelected }, () => {
      //if user is changing selected time, update show forecast notification a null, so notification will be shown next time
      if (!_.isEqual(this.state.timeSelected, this.state.defaultTime)) {
        this.props.setShownForecastBatteryNotification(null);
      }
    });

    let selectedHour = moment(
      timeSelected.hour +
        timeSelected.min +
        " " +
        timeSelected.meridiem.toLowerCase(),
      ["Hmm A"]
    );

    Promise.all([
      new Promise((resolve, reject) => {
        this.updateProperty(
          thingVuseProperties.TIMEZONE,
          parseInt(selectedHour.utcOffset() / 60, 10),
          CUSTOMER,
          resolve,
          reject
        )
      }),
      new Promise((resolve, reject) => {
        this.updateProperty(
          thingDeviceProperties.SELECTED_TIME,
          selectedHour,
          VUSE_DEVICE,
          resolve,
          reject
        )
      })
    ])
    .then(() => {
      this.setState({ spinnerLoading: false })
    })
    .catch(() => {
      this.setState({ spinnerError: true });
    })
    
  }

  _toggleLowBatteryAlert(isActiveLowBatteryAlert) {
    this.setState({ spinnerLoading: true });

    this.setState({ isActiveLowBatteryAlert });

    Promise.all([
      new Promise((resolve, reject) => {
        this.updateProperty(
          thingDeviceProperties.BATTERY_THRESHOLD_ENABLED,
          isActiveLowBatteryAlert,
          VUSE_DEVICE,
          resolve,
          reject
        )
      }),
      new Promise((resolve, reject) => {
        this.updateProperty(
          thingDeviceProperties.BATTERY_THRESHOLD,
          parseInt(this.state.percSelected.currentPerc.trim().slice(0, -1), 10),
          VUSE_DEVICE,
          resolve,
          reject
        )
      })
    ])
    .then(() => {
      this.setState({ spinnerLoading: false })
    })
    .catch(() => {
      this.setState({ spinnerError: true });
    })
  }

  _toggleBatterySaverAlert(isActiveBatterySaver) {
    const { currentDevice, deviceSpec, percSelectedBS, lowPowerMode } =
      this.state;

    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.BATTERY_SAVER, field_value: getAnalyticStatusBy(isActiveBatterySaver) === 'enabled' ? true : false});

    this.setState({ spinnerLoading: true });

    if (isActiveBatterySaver && !lowPowerMode) {
      updateDeviceInReducer(
        currentDevice?.serialNumber,
        {
          prevPowerLevel: currentDevice?.cloudInfo?.powerLevel,
        },
        false
      );
    }

    const selectedPerc = parseInt(
      percSelectedBS.currentPerc.trim().slice(0, -1),
      10
    );

    const balancedMode = deviceSpec?.cloudSize.find(
      (cl) => cl.type === MODE_BALANCED
    );

    updateCloudConfig(currentDevice, {
      batterySavingEnabled: isActiveBatterySaver ? 1 : 0,
      batterySavingThresholdValue: selectedPerc,
      batterySavingPowerLevel: parseInt(deviceSpec?.lowPower?.value, 10),
      ...(!isActiveBatterySaver && {
        powerLevel:
          currentDevice?.prevPowerLevel || parseInt(balancedMode?.value, 10),
      }),
    })
    .then(() => {
      this.setState({ isActiveBatterySaver });

      if (!isActiveBatterySaver) {
        this.props.setShownBatterySaverOnNotification(false);
        this.props.setShownBatterySaverOffNotification(false);
      }
  
      Promise.all([
        new Promise((resolve, reject) => {
          this.updateProperty(
            thingDeviceProperties.BATTERY_SAVER_ENABLED,
            isActiveBatterySaver,
            VUSE_DEVICE,
            resolve,
            reject
          );
        }),
        new Promise((resolve, reject) => {
          this.updateProperty(
            thingDeviceProperties.BATTERY_SAVER_THRESHOLD,
            parseInt(this.state.percSelectedBS.currentPerc.trim().slice(0, -1), 10),
            VUSE_DEVICE,
            resolve,
            reject
          );
        })
      ])
      .then(() => {
        this.setState({ spinnerLoading: false })
      })
      .catch(() => {
        this.setState({ spinnerError: true })
      })
    })
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }

  _toggleCheckBatteryAlert(isActiveCheckBatteryAlert) {
    this.setState({ spinnerLoading: true })

    this.setState({ isActiveCheckBatteryAlert });

    let selectedHour = moment(
      this.state.timeSelected.hour +
        this.state.timeSelected.min +
        " " +
        this.state.timeSelected.meridiem.toLowerCase(),
      ["Hmm A"]
    );

    Promise.all([
      new Promise((resolve, reject) => {
        this.updateProperty(
          thingDeviceProperties.BATTERY_FORECAST_ENABLED,
          isActiveCheckBatteryAlert,
          VUSE_DEVICE,
          resolve,
          reject
        );
      }),
      new Promise((resolve, reject) => {
        this.updateProperty(
          thingVuseProperties.TIMEZONE,
          parseInt(selectedHour.utcOffset() / 60, 10),
          CUSTOMER,
          resolve,
          reject
        );
      }),
      new Promise((resolve, reject) => {
        this.updateProperty(
          thingDeviceProperties.SELECTED_TIME,
          selectedHour,
          VUSE_DEVICE,
          resolve,
          reject
        );
      }),
    ])
    .then(() => {
      this.setState({ spinnerLoading: false })
    })
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }

  _toggleFullBatteryAlert(isActiveFullBatteryAlert) {
    this.setState({ spinnerLoading: true, isActiveFullBatteryAlert })

    this.updateProperty(
      thingDeviceProperties.FULL_CHARGE_ALERT,
      isActiveFullBatteryAlert,
      VUSE_DEVICE,
      () => this.setState({ spinnerLoading: false }),
      () => this.setState({ spinnerError: true })
    );
  }

  checkIsBatterySaverSupported(device) {
    isBatterySaverSupported(device)
      .then((isSupported) => {
        console.debug(
          "[BatterySettings] checkIsBatterySaverSupported",
          isSupported
        );
        this.setState({
          ...this.state,
          isBatterySaverSupported: isSupported,
        });
      })
      .catch((e) => {
        console.debug("[BatterySettings] checkIsBatterySaverSupported", e);
      });
  }

  setIsOpenBatterySaverPanel(isOpenBatterySaverPanel) {
    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW,{screen_name: AnalyticsScreenEventNames.BATTERY_SAVER_PICKER})
    this.setState({
      ...this.state,
      isOpenBatterySaverPanel,
    });
  }

  setBatterySaverPerc(value) {
    this.setState({
      ...this.state,
      percSelectedBS: { currentPerc: value },
    });
  }

  _handleSaveBatterySaverPreferences() {
    const { currentDevice, deviceSpec, lowPowerMode } = this.state;

    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SAVE});

    this.setState({ spinnerLoading: true });

    const selectedPerc = parseInt(
      this.state.percSelectedBS.currentPerc.trim().slice(0, -1),
      10
    );

    console.debug(
      "_handleSaveBatterySaverPreferences",
      currentDevice,
      this.state.lowPowerMode
    );

    const isBatteryBelow =
      currentDevice?.batteryInfo?.chargeLevel <= selectedPerc;

    if (isBatteryBelow) {
      updateDeviceInReducer(
        currentDevice?.serialNumber,
        {
          prevPowerLevel: currentDevice?.cloudInfo?.powerLevel,
        },
        false
      );
    }

    const balancedMode = deviceSpec?.cloudSize.find(
      (cl) => cl.type === MODE_BALANCED
    );

    updateCloudConfig(currentDevice, {
      batterySavingThresholdValue: selectedPerc,
      ...(!isBatteryBelow &&
        lowPowerMode && {
          powerLevel:
            currentDevice?.prevPowerLevel || parseInt(balancedMode?.value, 10),
        }),
    })
    .then(() => {
  
      this.updateProperty(
        thingDeviceProperties.BATTERY_SAVER_THRESHOLD,
        selectedPerc,
        VUSE_DEVICE,
        () => {
          this.setState({
            ...this.state,
            isOpenBatterySaverPanel: false,
            defaultPercBS: this.state.percSelectedBS.currentPerc,
          })

          this.setState({ spinnerLoading: false })
        },
        () => this.setState({ spinnerError: true })
      )
    })
    .catch(() => {
      this.setState({ spinnerError: true })
    })
  }

  _handleDisableBatterySaver() {
    const { currentDevice, deviceSpec } = this.state;

    this.setState({ spinnerLoading: true });

    const balancedMode = deviceSpec?.cloudSize.find(
      (cl) => cl.type === MODE_BALANCED
    );

    updateCloudConfig(currentDevice, {
      batterySavingEnabled: 0,
      powerLevel:
        currentDevice?.prevPowerLevel || parseInt(balancedMode?.value, 10),
    })
      .then(() => {
        this.setState({
          isActiveBatterySaver: false,
          batterySaverDisableAlertIsOpen: false,
        });

        this.setState({ spinnerLoading: false });
      })
      .catch((err) => this.setState({ spinnerError: true }));
  }

  _handleCancelBatterySaver() {
    this.setState({
      batterySaverDisableAlertIsOpen: false,
    });
  }

  render() {
    const {
      currentDevice,
      percSelected,
      lowPowerMode,
      defaultPerc,
      defaultPercBS,
      timeSelected,
      defaultTime,
      isActiveCheckBatteryAlert,
      isActiveLowBatteryAlert,
      isActiveFullBatteryAlert,
      isBatterySaverSupported,
      isActiveBatterySaver,
      isOpenBatterySaverPanel,
      percOptions,
      percSelectedBS,
      batterySaverDisableAlertIsOpen,
    } = this.state;

    let batteryPerc = currentDevice?.batteryInfo?.chargeLevel ?? 0;
    let batteryColor = Utils.getBatteryCircleColorFromPercentage(
      currentDevice?.batteryInfo,
      currentDevice?.cloudInfo?.powerLevel ?? 10
    );
    let batteryIcon = Utils.getBatteryIconFromPercentage(
      currentDevice?.batteryInfo,
      currentDevice?.cloudInfo?.powerLevel ?? 10,
      lowPowerMode
    );

    return (
      <React.Fragment>
        <div className="page w-100">
          <Header
            leftButton={{
              className: "play-tutorial",
              icon: <span className="play-tutorial-icon" />,
              onClick: this.goToTutorial,
            }}
            rightButton={{
              icon: <span className="bat-icon-close" />,
              onClick: this.goToDashboard,
            }}
          >
            {this.dictionary.BATT_PREF_HEADER}
          </Header>
          <div className="battery-settings-wrapper">
            <div className="battery-settings-values d-flex align-items-center justify-content-around">
              <div className="progress-circle-battery-settings">
                <span className={`${batteryIcon}`}></span>
                <div className="percent">
                  <svg>
                    <circle
                      cx="42"
                      cy="42"
                      r="42"
                      className="circle-first"
                    ></circle>
                    <circle
                      cx="42"
                      cy="42"
                      r="42"
                      className="circle-second"
                      style={{
                        strokeDashoffset: `${261 - (261 * batteryPerc) / 100}`,
                        stroke: batteryColor,
                      }}
                    ></circle>
                  </svg>
                </div>
              </div>
              <div className="battery-settings-values-percent">
                {batteryPerc}%
              </div>
            </div>

            {/* <div className="label-title p-0">
              {this.dictionary.BATT_PREF_LOW_POWER_MODE_TITLE}
            </div>
            <div className="battery-settings-alerts d-flex">
              <span className="bat-icon-alert-circle"></span>
              {this.state.isBatterySaverSupported
                ? this.dictionary.BATT_PREF_LOW_POWER_MODE_CONTENT
                : this.dictionary.BATT_PREF_LOW_POWER_MODE_CONTENT_NO_BS}
            </div> */}

            <div className="battery-settings-box">
              <div className="d-flex align-items-center">
                <div className="battery-settings-box-title d-flex align-items-center fs-16">
                  <span
                    className={`${
                      lowPowerMode
                        ? "bat-icon-battery-in-charge"
                        : "bat-icon-battery"
                    } fs-24 me-2`}
                  ></span>
                  {this.dictionary.BATT_PREF_LOW_POWER_MODE}
                </div>
                <div className="form-check form-switch ms-auto">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="low-power-mode-toggle"
                    value={lowPowerMode}
                    checked={lowPowerMode}
                    onChange={this.handleSetLowPowerMode}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="low-power-mode-toggle"
                  ></label>
                </div>
              </div>
            </div>

            {isBatterySaverSupported && (
              <>
                <div className="battery-settings-box">
                  <div className="d-flex align-items-center">
                    <div className="battery-settings-box-title">
                      {this.dictionary.BATT_PREF_BATTERY_SAVER_TITLE}
                    </div>
                    <div className="form-check form-switch ms-auto">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="battery-saver-toggle"
                        checked={isActiveBatterySaver}
                        value={isActiveBatterySaver}
                        disabled={false}
                        onChange={() =>
                          this.toggleBatterySaverAlert(!isActiveBatterySaver)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="battery-saver-toggle"
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-2">
                    <div className="col-8">
                      <div className="battery-settings-box-text">
                        {this.dictionary.BATT_PREF_BATTERY_SAVER_BODY}
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => this.setIsOpenBatterySaverPanel(true)}
                        disabled={!this.state.isActiveBatterySaver}
                      >
                        {defaultPercBS}
                      </button>
                    </div>
                  </div>
                </div>
                <PickerPanel
                  isOpenPanel={isOpenBatterySaverPanel}
                  pickerOptions={percOptions}
                  pickerSelected={percSelectedBS}
                  panelTitle={this.dictionary.BATT_PREF_BATTERY_SAVER_HEADER}
                  panelDescription={
                    this.dictionary.BATT_PREF_BATTERY_SAVER_SUBHEADER
                  }
                  buttonLabel={
                    this.dictionary.BATT_PREF_SAVE_BTN_CHOOSE_0_ctaLabel
                  }
                  onPanelDismiss={() => {
                    this.setIsOpenBatterySaverPanel(false);
                    this.setBatterySaverPerc(defaultPercBS);
                    logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.RECHARGE_REMINDERS})
                  }}
                  onPickerSelection={(_, value) => {
                    this.setBatterySaverPerc(value);
                    logAnalyticsEvent(AnalyticsEvents.FORM_CHANGED, {field_name: AnalyticsFieldNames.BATTERY_SAVER_THRESHOLD, field_value: value})
                  }}
                  onPanelClose={this.handleSaveBatterySaverPreferences}
                />
              </>
            )}

            <div className="label-title p-0 mt-4">
              {this.dictionary.BATT_PREF_RECHARGE_REMINDERS_TITLE}
            </div>

            <BatteryRemindersOptions
              toggleLowBatteryAlert={this.toggleLowBatteryAlert}
              toggleCheckBatteryAlert={this.toggleCheckBatteryAlert}
              toggleFullBatteryAlert={this.toggleFullBatteryAlert}
              saveNewDefaultPercentage={this.saveNewDefaultPercentage}
              saveNewDefaultTime={this.saveNewDefaultTime}
              currentIsActiveLowBatteryAlert={isActiveLowBatteryAlert}
              currentIsActiveCheckBatteryAlert={isActiveCheckBatteryAlert}
              currentIsActiveFullBatteryAlert={isActiveFullBatteryAlert}
              currentDefaultPerc={defaultPerc}
              currentPercSelected={percSelected}
              currentTimeSelected={timeSelected}
              currentDefaultTime={defaultTime}
              currentDevice={currentDevice}
            />
          </div>

          <div className="battery-settings-bg-value">
            <div className="page-linear-gradient-bg-top-left" />
            <div
              key={lowPowerMode}
              className={cx("battery-settings-intensity", {
                low: lowPowerMode,
              })}
            />
          </div>

          <CustomSlidingPanel
            from="bottom"
            isOpen={batterySaverDisableAlertIsOpen}
            overlayClassName="sliding-common-wrapper priority"
            className="onboarding-panel mid-height-panel"
            title={this.dictionary.BATT_PREF_BATTERY_SAVER_HEADER}
            backdropClose={false}
          >
            <div className="process-data-container px-0 pb-0">
              <div className="process-data-title">
                <div>
                  {Utils.stringReplacePlaceholders(
                    this.dictionary.BATT_PREF_BATTERY_SAVER_ON_CONTENT,
                    [`<strong>${currentDevice?.deviceCustomName || ""}</strong>`],
                    true
                  )}
                </div>
                <br />
                <div>{this.dictionary.BATT_PREF_BATTERY_SAVER_ON_SUBCONTENT}</div>
              </div>
              <div className="process-data-image">
                <span className="bat-icon-battery-in-charge" />
              </div>
              <div className="process-data-cta with-bg d-grid gap-2">
                <button
                  className="btn btn-primary text-uppercase"
                  onClick={this.handleDisableBatterySaver}
                >
                  {this.dictionary.BATT_PREF_BATTERY_SAVER_BTN_DISABLE_0_ctaLabel}
                </button>
                <button
                  className="btn btn btn-outline-secondary text-uppercase"
                  onClick={this.handleCancelBatterySaver}
                >
                  {this.dictionary.BATT_PREF_BATTERY_SAVER_BTN_CANCEL_0_ctaLabel}
                </button>
              </div>
            </div>
          </CustomSlidingPanel>
        </div>

        <SpinnerModal
          show={this.state.spinnerLoading}
          error={this.state.spinnerError}
          download={false}
          onClose={() => this.setState({ spinnerLoading: false, spinnerError: false })}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    aem: { currentCountry, currentLanguage },
    deviceReducer: { devices },
    onboardingReducer: { userPin },
    commonsReducer: { things },
  } = state;

  return {
    currentCountry,
    currentLanguage,
    devices,
    userPin,
    things,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setThings: (
      properties,
      tenantUserId,
      uuid,
      vendor,
      successCb,
      failureCb
    ) => {
      dispatch(
        commonsActions.setThings(
          properties,
          tenantUserId,
          uuid,
          vendor,
          successCb,
          failureCb
        )
      );
    },
    getThings: (tenantUserId, uuid, vendor, successCb, failureCb) => {
      dispatch(
        commonsActions.getThings(
          tenantUserId,
          uuid,
          vendor,
          successCb,
          failureCb
        )
      );
    },
    setShownForecastBatteryNotification: (value) => {
      dispatch(tipsActions.setShownForecastBatteryNotification(value));
    },
    setShownBatterySaverOnNotification: (value) => {
      dispatch(tipsActions.setShownBatterySaverOnNotification(value));
    },
    setShownBatterySaverOffNotification: (value) => {
      dispatch(tipsActions.setShownBatterySaverOffNotification(value));
    },
    setUserSettings: (settingName, value, successCb, failureCb) => {
      dispatch(
        userActions.setUserSettings(settingName, value, successCb, failureCb)
      );
    },
  };
}

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BatterySettings);
export { connectedComponent as BatterySettings };
