import React from 'react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';
import { Header, OnboardingHeader } from '../../_components';
import { aemPages, routingConstants, AnalyticsEvents, AnalyticsTargetEventsNames } from '../../_constants';
import { AEMHelper, propertyHeadingDefaultEmpty } from '../../_helpers/aem/aemhelper';
import { buildURI } from '../../_helpers/navigation';
import { Webview } from '../../_helpers/webview';
import { 
    getAccessToken, 
    userActions, 
    setUserData 
} from '../../_actions';
import { Tenants } from '../../_helpers/tenants';
import { isValidString } from '../../_helpers/utils';
import { AccountEditDetailsForm } from '../../_components/CustomForms/AccountEditDetailsForm';
import { logAnalyticsEvent } from "../../_helpers/analytics/logAnalytics";

class EditDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            emailAlreadyExists: false,
            invalidPassword: false, //don't know if use this error or manage if like generic error
            error: false
        }

        this.handleBack = this._handleBack.bind(this);
        this.saveDetails = this._saveDetails.bind(this);


        this.aem = new AEMHelper();
        this.dictionary = this.aem.getDictionary(aemPages.EDIT_DETAILS, {
            EDIT_DETAILS_HEADER: propertyHeadingDefaultEmpty
        });
    }

    componentDidMount() {

    }

    _handleBack() {
        this.props.history.push({
            pathname: buildURI(routingConstants.MANAGE_ACCOUNT)
        });

        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.BACK});
    }

    _saveDetails(formData) {

        console.log("formData", formData);

        const { updateUserInfo } = this.props;
        let payload = {};

        if(isValidString(formData.oldPassword)){
            payload = {
                ...payload,
                "oldPassword": formData.oldPassword
            }
        }

        if(isValidString(formData.newPassword)){
            payload = {
                ...payload,
                "password": formData.newPassword
            }
        }


        this.setState({
            emailAlreadyExists: false,
            invalidPassword: false,
            error: false
        }, () => {
            updateUserInfo(payload,
                (response) => {
                    if(response?.user){
                        setUserData(response.user);
                    }
                },
                (error) => {
                    if (error?.response?.data?.message === "emailAlreadyExists") {
                        this.setState({
                            emailAlreadyExists: true,
                            invalidPassword: false,
                            error: false
                        });
                    } else if (error?.response?.data?.message === "invalidPassword") {
                        this.setState({
                            emailAlreadyExists: false,
                            invalidPassword: true,
                            error: true
                        });
                    } else {
                        this.setState({
                            emailAlreadyExists: false,
                            invalidPassword: false,
                            error: true
                        });
                    }
                });
        });

        logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.SAVE});

    }

    render() {

        const { loading } = this.props;
        const { error, emailAlreadyExists } = this.state;

        return (
            <div className="page w-100">
                <Header 
                    leftButton={{
                        icon: <span className="bat-icon-back" />,
                        onClick: this.handleBack
                    }}
                >
                    {this.dictionary.EDIT_DETAILS_HEADER}
                </Header>
                <AccountEditDetailsForm onSubmit={this.saveDetails} loading={loading}
                    emailAlreadyExists={emailAlreadyExists}
                    error={error} />
                
            </div>
        )
    }
}


function mapStateToProps(state) {

    return {
        loading: state.onboardingReducer.loading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUserInfo: (data, successCb, failureCb) => {
            dispatch(userActions.updateUserInfo(data, successCb, failureCb))
        },
        checkEmail: (formData, successCb, failureCb) => {
            dispatch(userActions.checkEmail(formData, successCb, failureCb));
        },
    };
}

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(EditDetails);
export { connectedComponent as EditDetails };