import React, { useEffect, useState } from 'react';
import { aemPages, imagesConstants, thingVuseProperties } from '../../_constants';
import { AEMHelper, propertyCtaItemsDefaultEmpty, propertyHeadingDefaultEmpty, propertyTextDefaultEmptyParsed } from '../../_helpers/aem/aemhelper';
import { debug } from '../../_helpers/debug';
import { CustomSlidingPanel } from '../CustomSlidingPanel';
import { PageSelectorCTA } from '../PageSelectorCTA';
import BrowserHelper from '../../_helpers/browser/browser.helper';
import { PermissionsHelper } from '../../_helpers/permissions/permissions.helper';
import cx from "classnames";
import { getNotificationCheck, getUserEverLogged, setNotificationCheck } from '../../_actions';
import moment from 'moment';
import { Commons } from '../../_helpers/commons';
import { CustomLoaderOverlay } from '../CustomLoaderOverlay';

export const NotificationCheckPanel = (props) => {

  const [dictionary, setDictionary] = useState({});
  const [ isOpen, setIsOpen ] = useState(false);
  const [ missingPermissionOS, setMissingPermissionOS ] = useState(false);
  const [ missingPermissionNuviu, setMissingPermissionNuviu ] = useState(false);
  const [ buttonLabel, setButtonLabel ] = useState('');
  const [ buttonAction, setButtonAction ] = useState(() => {});
  const [ buttonSecondaryLabel, setButtonSecondaryLabel ] = useState('');
  const [ buttonSecondaryAction, setButtonSecondaryAction ] = useState(() => {});
  const [ contentText, setContentText ] = useState('');
  const [ contentIcon, setContentIcon ] = useState(null);
  const [ title, setTitle ] = useState('');
  const [ isButtonSecondary, setIsButtonSecondary] = useState(true);
  const [ dataNotificationCheck, setDataNotificationCheck ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    const aem = new AEMHelper();
    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_NOTIF_DISABLED_NUVIU_IOS: propertyTextDefaultEmptyParsed,
        HOME_CHECK_NOTIF_SETT_HEADER: propertyHeadingDefaultEmpty,
        HOME_BTN_CHANGE_SETT: propertyCtaItemsDefaultEmpty,
        HOME_DISMISS_LINK: propertyTextDefaultEmptyParsed,
        HOME_BTN_REMIND_LATER: propertyCtaItemsDefaultEmpty,
        HOME_BTN_IOS_SETTINGS: propertyCtaItemsDefaultEmpty,
        HOME_BTN_NUVIU_SETTINGS: propertyCtaItemsDefaultEmpty,
        HOME_NOTIF_DISABLED_SYSTEM_PREF: propertyTextDefaultEmptyParsed,
        HOME_NOTIF_DISABLED_NUVIU_BROWSER: propertyTextDefaultEmptyParsed,
        HOME_HEADER_CHECK_IOS_SETT: propertyHeadingDefaultEmpty,
        HOME_HEADER_CHECK_NUVIU_SETT: propertyHeadingDefaultEmpty,
        HOME_BTN_REMIND_TWO_WEEKS: propertyCtaItemsDefaultEmpty,
        HOME_WANT_CHANGE_SETT_CONTENT: propertyTextDefaultEmptyParsed,
        HOME_ENABLE_FOLLOWING_NOTIF_CONTENT: propertyTextDefaultEmptyParsed,
        HOME_BTN_SHOW_AGAIN: propertyCtaItemsDefaultEmpty
      })
    );

    const dataCheck = getNotificationCheck()
    setDataNotificationCheck(dataCheck)

    //check if is snoozed
    if (!dataCheck?.snoozed) {
      const currentDate = moment();

      //get if there is a 2 weeks snooze
      const _appConfigString = localStorage.getItem("appConfig")
      const appConfig = JSON.parse(_appConfigString)
      const loginDaysCheck = 1//appConfig?.days_until_notifications_permissions_check

      //get last login date
      const customerThing = Commons.getCustomerThing();
      const lastLogin = customerThing?.properties.find(prop => prop.type === thingVuseProperties.MKT_LASTACTIVE)?.last_update

      const daysDifference = currentDate.diff(moment.utc(lastLogin), 'hours')//days
      //check if it has been more than 2 weeks since the user snoozed the popup or it has been 1 month since the last login
      if ((!dataCheck.checkAgain || moment(dataCheck.checkAgain).isBefore()) && daysDifference >= loginDaysCheck) {

        checkPermissions()

        setContentIcon(bellIcon)

        document.addEventListener("visibilitychange", () => {
          checkPermissions()
        })
      }
    }
  }, []);

  const checkPermissions = async () => {
    if (BrowserHelper.isBLEBrowser()) {
      try {
        const permissionOs = await BrowserHelper.checkOSNotificationPermissionStatusOnly()
        if (PermissionsHelper.hasGrantedStatus(permissionOs)) {
          setMissingPermissionOS(false)
        } else {
          setMissingPermissionOS(true)
        }
      } catch (error) {
        debug('[BrowserHelper] checkOSNotificationPermissionStatusOnly', error)
      }

      try {
        const permissionNuviu = await BrowserHelper.checkPWANotificationPermissionStatus()
        if (
          PermissionsHelper.hasDefaultStatus(permissionNuviu?.state) ||
          PermissionsHelper.hasDeniedStatus(permissionNuviu?.state)
        ) {
          setMissingPermissionNuviu(true)
        } else {
          setMissingPermissionNuviu(false)
        }
      } catch (error) {
        debug('[BrowserHelper] checkPWANotificationPermissionStatus', error)
      }
    }
  }

  useEffect(() => {
    if (missingPermissionOS && missingPermissionNuviu) {
      setButtonLabel(dictionary.HOME_BTN_CHANGE_SETT_0_ctaLabel)
      setButtonAction(handlePermissionAll)
      setContentText(dictionary.HOME_NOTIF_DISABLED_NUVIU_IOS)
      setTitle(dictionary.HOME_CHECK_NOTIF_SETT_HEADER)
      setButtonSecondaryLabel(dictionary.HOME_BTN_REMIND_LATER_0_ctaLabel)
      setButtonSecondaryAction(handleRemind)
      setIsOpen(true)
    } else if (missingPermissionOS) {
      setButtonLabel(osPermissionButton)
      setButtonAction(handlePermissionOs)
      setContentText(dictionary.HOME_NOTIF_DISABLED_SYSTEM_PREF)
      setTitle(dictionary.HOME_HEADER_CHECK_IOS_SETT)
      setButtonSecondaryLabel(dictionary.HOME_BTN_REMIND_LATER_0_ctaLabel)
      setButtonSecondaryAction(handleRemind)
      setIsOpen(true)
    } else if (missingPermissionNuviu) {
      setButtonLabel(nuviuPermissionButton)
      setButtonAction(handlePermissionNuviu)
      setContentText(dictionary.HOME_NOTIF_DISABLED_NUVIU_BROWSER)
      setTitle(dictionary.HOME_HEADER_CHECK_NUVIU_SETT)
      setButtonSecondaryLabel(dictionary.HOME_BTN_REMIND_LATER_0_ctaLabel)
      setButtonSecondaryAction(handleRemind)
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
    setIsButtonSecondary(true)

  }, [missingPermissionNuviu, missingPermissionOS])

  const handlePermissionAll = () => () => {
    setContentIcon(settingsIcon)
    setContentText(dictionary.HOME_ENABLE_FOLLOWING_NOTIF_CONTENT)
    setButtonLabel(osPermissionButton)
    setButtonAction(handlePermissionOs)
    setButtonSecondaryLabel(nuviuPermissionButton)
    setButtonSecondaryAction(handlePermissionNuviu)
    setIsButtonSecondary(false)
  }

  const handlePermissionOs = () => () => {
    BrowserHelper.checkOSNotificationPermissionStatus().then(() => {
      checkPermissions()
    })
  }

  const handlePermissionNuviu = () => () => {
    setIsLoading(true)
    BrowserHelper.enablePWANotificationPermission().then(() => {
      checkPermissions()
      setIsLoading(false)
    })
    .catch(error => {
      debug('[BrowserHelper] handlePermissionNuviu', error)
      setIsLoading(false)
    })
  }

  const handleDontShowAgain = () => () => {
    setNotificationCheck({
      ...dataNotificationCheck,
      snoozed: true
    })
    setIsOpen(false)
  }

  const handleRemindTwoWeeks = () => () => {
    setNotificationCheck({
      ...dataNotificationCheck,
      checkAgain: moment().add(1, 'hours')// TO UPDATE TO 14 AFTER TESTING IS DONE
    })
    setIsOpen(false)
  }

  const handleRemind = () => () => {
    setButtonLabel(dictionary.HOME_BTN_REMIND_TWO_WEEKS_0_ctaLabel)
    setButtonAction(handleRemindTwoWeeks)
    setContentText(dictionary.HOME_WANT_CHANGE_SETT_CONTENT)
    setButtonSecondaryLabel(dictionary.HOME_BTN_SHOW_AGAIN_0_ctaLabel)
    setButtonSecondaryAction(handleDontShowAgain)
  }

  const bellIcon = 
    <div className="bat-icon-bell main-icon">
      <div className='dot-red'></div>
    </div>

  const settingsIcon = 
    <div className="bat-icon-settings main-icon"></div>

  const osPermissionButton =
    <>
      <img className="button-icon" src={imagesConstants.SYSTEM_ICON} alt="Settings logo" />
      {dictionary.HOME_BTN_IOS_SETTINGS_0_ctaLabel}
    </>

  const nuviuPermissionButton =
    <>
      <img className="button-icon" src={imagesConstants.NUVIU_ICON} alt="Nuviu logo" />
      {dictionary.HOME_BTN_NUVIU_SETTINGS_0_ctaLabel}
    </>

  return (
    <>
      <CustomSlidingPanel
        from="bottom"
        isOpen={isOpen}
        title={title}
        overlayClassName="sliding-common-wrapper homepage-priority"
        className="onboarding-panel mid-height-panel notification-check"
        backdropClose={false}
      >
        <div className="process-data-container">
          <div className='main-icon-container'>
            {contentIcon}
            
            <div className='main-content'>
              {contentText}
            </div>
          </div>
        </div>
        <PageSelectorCTA sticky={false}>
          <div className="d-grid gap-2">
            <button
              className="btn btn-primary text-uppercase"
              onClick={buttonAction}
            >
              {buttonLabel}
            </button>
            
            <button
              className={cx("btn text-uppercase", {"btn-outline-secondary": isButtonSecondary, "btn-primary": !isButtonSecondary})}
              onClick={buttonSecondaryAction}
            >
              {buttonSecondaryLabel}
            </button>
          </div>
        </PageSelectorCTA>
      </CustomSlidingPanel>

      {isLoading && <CustomLoaderOverlay forceShow={true} />}
    </>
  );
};