import React, { useEffect, useState } from "react";
import { aemPages, ANIMATION_DURATION, imagesConstants, routingConstants } from "../../_constants";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { CustomModal } from "../../_components/CustomModal";
import { CustomLoaderOverlay, Header, PageSelectorCTA } from "../../_components";
import { CustomLoadingButton } from "../../_components/CustomLoadingButton";
import { PermissionsHelper } from "../../_helpers/permissions/permissions.helper";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
} from "../../_helpers/aem/aemhelper";
import { NOTIFICATION_PERMISSION_PANEL_TYPES } from "../../_components/NotificationPermissionPanels/NotificationPermissionPanel";
import cx from "classnames";

export const NotificationPermissions = ({
  title,
  from,
  onClose,
  setNotifPermissionActivePanelType
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [dictionary, setDictionary] = useState({});
  const [isIosSupported, setIsIosSupported] = useState(false);
  const [isNuviuSupported, setIsNuviuSupported] = useState(false);
  const [showLabel, setShowLabel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_UPDATE_SETTINGS_TITLE: propertyTextDefaultEmpty,
        HOME_BTN_IOS_SETTINGS: propertyCtaItemsDefaultEmpty,
        HOME_BTN_NUVIU_SETTINGS: propertyCtaItemsDefaultEmpty,
        HOME_NOTIF_DISABLED_SYSTEM_PREF: propertyTextDefaultEmpty,
        HOME_NOTIF_DISABLED_NUVIU_BROWSER: propertyTextDefaultEmpty,
        HOME_NOTIF_DISABLED_NUVIU_IOS: propertyTextDefaultEmpty,
        HOME_NOTIF_UPDATES_LINK: propertyTextDefaultEmpty
      })
    );

    setTimeout(function() {
      checkPermissions();
    }, ANIMATION_DURATION)

    /* when app goes in foreground recheck the permissions */
    window.document.addEventListener("visibilitychange", handleAppChangeState);

    return () => {
      window.document.removeEventListener(
        "visibilitychange",
        handleAppChangeState
      );
    };
  }, []);

  useEffect(() => {
    if (isIosSupported && !isNuviuSupported) {
      setText("HOME_NOTIF_DISABLED_NUVIU_BROWSER");
    } else if (!isIosSupported && isNuviuSupported) {
      setText("HOME_NOTIF_DISABLED_SYSTEM_PREF");
    } else if (!isIosSupported && !isNuviuSupported) {
      setText("HOME_NOTIF_DISABLED_NUVIU_IOS");
    } else if (isIosSupported && isNuviuSupported) {
      setIsOpen(false);
    }
  }, [isIosSupported, isNuviuSupported]);

  useEffect(() => {
    if (from == routingConstants.NOTIFICATION_HISTORY_OPEN) {
      setShowLabel(true)
    } else {
      setShowLabel(false)
    }
  }, [from])


  const handleAppChangeState = async () => {
    if (document.visibilityState === "visible") {
      checkPermissions();
    }
  };

  const checkPermissions = async () => {
    try {
      /* NUVIU */
      const response =
        await BrowserHelper.checkPWANotificationPermissionStatus();
      //alert(response.status);
      if (PermissionsHelper.hasGrantedStatus(response.state)) {
        setIsNuviuSupported(true);
      } else {
        setIsNuviuSupported(false);
        setIsOpen(true);
      }
    } catch (e) {
      setIsNuviuSupported(false);
      setIsOpen(true);
    }
    setIsLoading(false)

    try {
      /* IOS */
      const response = await navigator.permissions.query({name: 'notifications', system: true})
      //alert(response?.status);
      if (PermissionsHelper.hasGrantedStatus(response?.state)) {
        setIsIosSupported(true);
      } else {
        setIsIosSupported(false);
        setIsOpen(true);
      }
    } catch (e) {
      setIsIosSupported(false);
      setIsOpen(true);
    }
  };

  const handleBackToFeature = () => {
    setIsOpen(false)
    if (onClose) {
      onClose()
    }
  };

  const handleIOSSettings = () => {
    BrowserHelper.checkOSNotificationPermissionStatus();
  };

  const handleNuviuSettings = async () => {
    try {
      setIsLoading(true)
      const response = await BrowserHelper.enablePWANotificationPermission();
      // if(!isNil(response) && PermissionsHelper.hasGrantedStatus(response.state)){

      // }
      checkPermissions();
    } catch (e) {
      setIsLoading(false)
      //alert("Something wen wrong");
      //setIsOpen(false);
      setNotifPermissionActivePanelType(NOTIFICATION_PERMISSION_PANEL_TYPES.ERROR);
    }
  };

  return (
    <>
      <CustomModal isOpen={isOpen} className={"optin-modal optin-modal-higher"} isNotification={true} closeTimeout={0}>
        <div className="page w-100">
          <div className="optin-wrapper h-100 update-settings">
            <Header
              rightButton={{
                icon: <span className="bat-icon-close" />,
                onClick: handleBackToFeature,
              }}
            >
              {title}
            </Header>
            <div className="optin-content notification-wrapper notification-wrapper-props">
              <div className="optin-title">
                <span
                  style={{
                    fontSize: "60px",
                    marginBottom: "26px",
                    marginTop: "66px",
                    display: "block",
                  }}
                >
                  <span
                    className="bat-icon-bell"
                    style={{ position: "relative" }}
                  >
                    <span
                      style={{
                        height: "20px",
                        width: "20px",
                        backgroundColor: "#e53237",
                        borderRadius: "50%",
                        position: "absolute",
                        left: "38px",
                      }}
                    />
                  </span>
                </span>

                <h1>{dictionary.HOME_UPDATE_SETTINGS_TITLE}</h1>
              </div>
              <div className="optin-description" style={{ marginTop: "41px" }}>
                {dictionary[text]}
              </div>
            </div>
            <PageSelectorCTA sticky={true} className={cx('update-settings-cta', {'pb-0': showLabel})}>
              <div className="d-grid gap-2">
                {!isIosSupported ? (
                  <CustomLoadingButton
                    isLoading={false}
                    validationMethod={null}
                    buttonLabel={<><img className="button-icon" src={imagesConstants.SYSTEM_ICON} alt="Settings logo" />{dictionary.HOME_BTN_IOS_SETTINGS_0_ctaLabel}</>}
                    onClickMethod={handleIOSSettings}
                    buttonClassName="btn btn-primary text-uppercase"
                  />
                ) : null}
                {!isNuviuSupported ? (
                  <CustomLoadingButton
                    isLoading={false}
                    validationMethod={null}
                    buttonLabel={<><img className="button-icon" src={imagesConstants.NUVIU_ICON} alt="Nuviu logo" />{dictionary.HOME_BTN_NUVIU_SETTINGS_0_ctaLabel}</>}
                    onClickMethod={handleNuviuSettings}
                    buttonClassName="btn btn-primary text-uppercase"
                  />
                ) : null}
                
              </div>

              {showLabel ? (
                <div className="view-notification-label" onClick={() => setIsOpen(false)}>
                  <span>{dictionary.HOME_NOTIF_UPDATES_LINK}</span>
                </div>
              ) : null}
            </PageSelectorCTA>

            <div className="page-linear-gradient-bg"></div>
          </div>
        </div>
      </CustomModal>

      {isLoading && <CustomLoaderOverlay forceShow={true} />}
    </>
  );
};
