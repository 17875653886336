import cx from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Swiper, SwiperSlide } from "swiper/react";
import useResizeObserver from "use-resize-observer";
import { dashboardActions, deviceActions, getUserData, getUserEverLogged } from "../../_actions";
import {
  getDeviceSpec,
  getHomepageDrawer,
  isSupportedService,
  getCountry, 
  getProvince,
  getSupportedDevices 
} from "../../_actions/appConfig.actions";
import {
  CustomButton,
  DeviceSelector,
  FeatureWidget,
  FeatureWidgetGroup,
  LockSwitch,
  ReconnectCTA,
  ReconnectLoader,
  StatusBar,
} from "../../_components";
import { FirmwareUpdate } from "../../_components/FirmwareUpdate/FirmwareUpdate";
import {
  AnalyticsEvents,
  AnalyticsScreenEventNames,
  AnalyticsTargetEventsNames,
  PROFILE_EPOD2,
  PROFILE_WAWE2,
  aemPages,
  imagesConstants,
  routingConstants,
  servicesConstants,
  thingVuseProperties,
} from "../../_constants";
import {
  AEMHelper,
  propertyCtaItemsDefaultEmpty,
  propertyTextDefaultEmpty,
  propertyTextDefaultEmptyParsed,
} from "../../_helpers/aem/aemhelper";
import { logAnalyticsEvent, logAnalyticsEventByCustomEvent } from "../../_helpers/analytics/logAnalytics";
import BrowserHelper from "../../_helpers/browser/browser.helper";
import { Commons } from "../../_helpers/commons";
import {
  clearAllLastSyncedLocations,
  getCurrentDevice,
  isDeviceConnected,
  handleDeviceSelection
} from "../../_helpers/device";
import {
  getDeviceHomeScreenImg,
  getDeviceIconImg,
  getFeatureWidgetImg,
} from "../../_helpers/device/assets";
import { getElementHeight } from "../../_helpers/dom";
import { history } from "../../_helpers/history";
import { buildURI } from "../../_helpers/navigation";
import { PermissionsHelper } from "../../_helpers/permissions/permissions.helper";
import { DashboardContext } from "../Dashboard";
import { NoDevices } from "./NoDevices";
import { calcAgeRange } from "../../_helpers/usage-tracker";
import { getTruncateAppVersion } from '../../_helpers/utils';
import moment from "moment";
import isNil from "lodash/isNil";
import { FirmwareUpdateCTA } from "../../_components/FirmwareUpdate/FirmwareUpdateCTA";

const DEVICE_IMG_MB = 24;
const DEVICE_SELECTOR_MB = 28;

const HomeScreen = ({ devices, setSelected, setSelectedDevice }) => {
  const [dictionary, setDictionary] = useState({});
  const { ref: deviceImgRef, height: deviceImgHeight = 1 } =
    useResizeObserver();
  const reconnectCTARef = useRef(null);
  const deviceSelectorRef = useRef(null);
  const drawerRef = useRef(null);
  const { headerHeight, navbarHeight } = useContext(DashboardContext) || {};
  const [deviceSelectorHeight, setDeviceSelectorHeight] = useState(0);
  const [currSlide, setCurrSlide] = useState(0);
  const [currDevice, setCurrDevice] = useState(null);
  const [isScrollable, setIsScrollable] = useState(false);
  const [swiper, setSwiper] = useState(null);
  const [widgets, setWidgets] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDisconnected, setIsDisconnected] = useState(false)
  const [isOpenDisconnectedAlert, setIsOpenDisconnectedAlert] = useState(false)
  const [closingTimeoutId, setClosingTimeoutId] = useState(false)
  const [watcherId, setWatcherId] = useState(null)
  const [availableDevices] = useState(getSupportedDevices());

  const handleSlideChange = (swiper) => {
    const index = swiper.realIndex;

    setCurrSlide(index);
    setCurrDevice(devices[index]);
    setSelectedDevice(index);
    setSelected(devices[index]);
  };

  const handleSpringStart = (event) => {
    setDeviceSelectorHeight(getElementHeight(deviceSelectorRef.current));

    requestAnimationFrame(() => {
      if (event.type === "SNAP" && event.source === "dragging") {
        setIsScrollable(
          drawerRef.current.height >= window.innerHeight - headerHeight
        );
      }
    });
  };

  const getCloudControlGradientHeight = () => {
    const deviceProfile =
      currDevice?.deviceType === PROFILE_EPOD2
        ? PROFILE_WAWE2
        : currDevice?.deviceType;
    const cloudControlSpec = getDeviceSpec(deviceProfile);
    const powerLevel = currDevice?.cloudInfo?.powerLevel || 0;

    if (!cloudControlSpec?.max) {
      return 0;
    }

    return (100 * powerLevel) / cloudControlSpec?.max;
  };

  const goToUsageTracker = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.USAGE_TRACKER})
    setSelected(currDevice, () => {
      Commons.goToFeaturePage({
        thingProperty: thingVuseProperties.OPT_IN_USAGE_TRACKER,
        route: {
          optinDone: routingConstants.USAGE_TRACKER,
          optinUndone: routingConstants.USAGE_TRACKER_TUTORIAL,
        },
      });
    });
  };

  const goToCloudControl = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.CLOUD_CONTROL})
    Commons.goToFeaturePage({
      thingProperty: thingVuseProperties.CLOUDCONTROL,
      analytics: {
        eventName: AnalyticsEvents.CLOUD_CONTROL,
      },
      device: currDevice,
      route: {
        optinDone: routingConstants.CLOUD_CONTROL,
        optinUndone: routingConstants.CLOUD_CONTROL_TUTORIAL,
      },
    });
  };

  const goToBatterySettings = () => {
    if(isDeviceConnected(currDevice)){
      logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.RECHARGE_REMINDERS})
    }
    Commons.goToFeaturePage({
      thingProperty: thingVuseProperties.BATTERYSAVE,
      analytics: {
        eventName: AnalyticsEvents.BATTERY,
      },
      device: currDevice,
      route: {
        optinDone: routingConstants.BATTERY_SETTINGS,
        optinUndone: routingConstants.BATTERY_SETTINGS_TUTORIAL,
      },
    });
  };

  const goToFindMyVape = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.FIND_MY_VAPE})
    setSelected(currDevice, () => {
      Commons.goToFeaturePage({
        thingProperty: thingVuseProperties.OPT_IN_FINDMYVAPE,
        analytics: {
          eventName: AnalyticsEvents.FIND_MY_VAPE,
        },
        route: {
          optinUndone: routingConstants.FIND_MY_VAPE_TUTORIAL,
        },
        onOptinDone: async () => {
          const permission = await BrowserHelper.getLocationPermission()

          if (PermissionsHelper.hasDeniedStatus(permission)) {
            clearAllLastSyncedLocations()
          }

          Commons.goTo(routingConstants.FIND_MY_VAPE)
        },
      });
    });
  };

  const goToDeviceSettings = () => {
    logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.DEVICE_SETTINGS})
    setSelected(currDevice, () =>
      Commons.goTo(routingConstants.DEVICE_SETTINGS, {
        type: "pop",
        close: true,
        backTo: routingConstants.DASHBOARD,
      })
    );
  };

  const getWidgetConfig = () => {
    return {
      CLOUD_CONTROL: {
        hasBackground: true,
        disabled: !isDeviceConnected(currDevice),
        isSupported: true,
        className: "cc-widget",
        icon: "puff",
        label: "HOME_LABEL_CLOUD_CONTROL",
        onClick: () => goToCloudControl(),
      },
      USAGE_TRACKER: {
        hasBackground: true,
        isSupported: isSupportedService(servicesConstants.USAGE_TRACKER),
        className: "ut-widget",
        icon: "usage",
        label: "HOME_LABEL_USAGE_TRACKER",
        onClick: () => goToUsageTracker(),
      },
      FIND_MY_VAPE: {
        hasBackground: true,
        isSupported: true,
        className: "fmv-widget",
        icon: "find-my-vape",
        label: "HOME_LABEL_FMV",
        onClick: () => goToFindMyVape(),
      },
      RECHARGE_REMINDERS: {
        hasBackground: true,
        disabled: !isDeviceConnected(currDevice),
        isSupported: true,
        className: "rr-widget",
        icon: "battery-in-charge",
        label: "HOME_LABEL_RECHARGE_REMINDER",
        onClick: () => goToBatterySettings(),
      },
    };
  };

  const checkForDisconnected = (isEnabled) => {
    if (BrowserHelper.isBLEBrowser()) {
      if (!isEnabled) {
        navigator.permissions.query({ name: 'bluetooth', system: true }).then((response) => {
          if(response?.state === 'granted') {
            setIsDisconnected(true)
          } else {
            setIsDisconnected(false)
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        setIsDisconnected(false)
      }
    }
  }

  useEffect(() => {
    if(devices.length > 0){
      localStorage.setItem('analytics_device_index', currSlide)
      logAnalyticsEvent(AnalyticsEvents.SCREEN_VIEW, {screen_name: AnalyticsScreenEventNames.HOMESCREEN})
    }
    setDeviceSelectorHeight(getElementHeight(deviceSelectorRef.current));

    const aem = new AEMHelper();

    setDictionary(
      aem.getDictionary(aemPages.HOMEPAGE, {
        HOME_BTN_RECONNECT: propertyCtaItemsDefaultEmpty,
        HOME_LABEL_CONNECTED: propertyTextDefaultEmpty,
        HOME_LABEL_DISCONNECTED: propertyTextDefaultEmpty,
        HOME_LABEL_USAGE_TRACKER: propertyTextDefaultEmpty,
        HOME_LABEL_FMV: propertyTextDefaultEmpty,
        HOME_LABEL_CLOUD_CONTROL: propertyTextDefaultEmpty,
        HOME_LABEL_RECHARGE_REMINDER: propertyTextDefaultEmpty,
        HOME_BTN_DEVICE_SETTINGS: propertyTextDefaultEmpty,
        HOME_BTN_FW_UPDATE: propertyTextDefaultEmpty,
        HOME_BLUETOOTH_OFF: propertyTextDefaultEmpty,
        HOME_ALERT_CONNECTIONS_BLUETOOTH: propertyTextDefaultEmptyParsed
      })
    );

    setWidgets(getHomepageDrawer());

    if(isSupportedService(servicesConstants.QUALTRICS)){
      manageQualtricsData();
    }

    if (navigator.bluetooth && navigator.bluetooth.watchStatus && navigator.bluetooth.isEnabled) {
      navigator.bluetooth.isEnabled()
      .then(isEnabled => {
        checkForDisconnected(isEnabled)
      })
      .catch(error => {
        console.log('Error: bluetooth isEnabled()')
      })

      const id = navigator.bluetooth.watchStatus(function(isEnabled) {
        checkForDisconnected(isEnabled)
      })

      setWatcherId(id)

      return () => {
        if (watcherId) {
          navigator.bluetooth.clearWatch(watcherId)
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!isDisconnected) {
      setIsOpenDisconnectedAlert(true)

      const timeoutId = setTimeout(() => {
        setIsOpenDisconnectedAlert(false)
      }, 3000)

      setClosingTimeoutId(timeoutId)
    }
  }, [isDisconnected])

  useEffect(() => {
    const index = devices.findIndex((device) => device?.selected);

    if (index === -1) {
      setCurrDevice(devices[0]);
    } else if (swiper) {
      setCurrDevice(devices[index]);
      swiper.slideTo(index, 0);
    }

    if (!devices.length) {
      logAnalyticsEventByCustomEvent(AnalyticsEvents.SCREEN_VIEW, {
        screen_name: AnalyticsScreenEventNames.HOME,
      });
    }
  }, [devices, swiper]);

  const widgetConfig = getWidgetConfig();

  const alertDisconnectedOnClick = () => {
    if (closingTimeoutId) {
      clearTimeout(closingTimeoutId)
      setClosingTimeoutId(false)
    }

    if (!isOpenDisconnectedAlert) {
      const timeoutId = setTimeout(() => {
        setIsOpenDisconnectedAlert(false)
      }, 3000)

      setClosingTimeoutId(timeoutId)
    }

    setIsOpenDisconnectedAlert(!isOpenDisconnectedAlert)
  }

  const manageQualtricsData = () => {
    
    const userData = getUserData();
    const currentDate = moment(); 
      
    console.log("[Qualtrics] gender", userData?.gender === "0" ? "na" : userData?.gender);
    console.log("[Qualtrics] age_range", calcAgeRange(userData?.dateOfBirth));
    console.log("[Qualtrics] country",  getCountry());
    console.log("[Qualtrics] province", getProvince());
    console.log("[Qualtrics] os", 'ios');
    console.log("[Qualtrics] app_version", getTruncateAppVersion());
    console.log("[Qualtrics] createdOn",  moment(getUserEverLogged()).valueOf());  
    if(!isNil(getUserEverLogged())){
      const daysDifference = currentDate.diff(moment(getUserEverLogged()), 'days');
      console.log("[Qualtrics] daysSinceRegistration",  daysDifference);      
    }

    if (window?.dataLayer?.length > 0) {
      window.dataLayer.push({ 'gender': userData?.gender === "0" ? "na" : userData?.gender });
      window.dataLayer.push({ 'age_range': calcAgeRange(userData?.dateOfBirth)});
      window.dataLayer.push({'country': getCountry()});
      window.dataLayer.push({'province': getProvince()});
      window.dataLayer.push({'os': 'ios'});
      window.dataLayer.push({'app_version': getTruncateAppVersion()});
      window.dataLayer.push({'timestamp': Date.now()});
      window.dataLayer.push({'createdOn': moment(getUserEverLogged()).valueOf() });
      if(!isNil(getUserEverLogged())){
        const daysDifference = currentDate.diff(moment(getUserEverLogged()), 'days');
        window.dataLayer.push({'daysSinceRegistration': daysDifference});     
      }
    }
  }

  const handleAvailableDeviceSelection = () => {
    if (availableDevices.length === 1){
      handleDeviceSelection(availableDevices[0].profile);
      history.push(buildURI(routingConstants.PAIR_DEVICE), {type: 'pop'});
    } else {
      history.push(buildURI(routingConstants.SELECT_DEVICE));
    }
  }

  return (
    <div
      className="homescreen-page"
      style={{
        height: `calc(100vh - ${navbarHeight}px)`,
      }}
    >
      {devices.length ? (
        <>
          <Swiper
            allowTouchMove={false}
            className="device-carousel"
            style={{ height: `${window.innerHeight}px` }}
            onInit={(swiper) => setSwiper(swiper)}
            onSlideChange={handleSlideChange}
          >
            {devices.map((device, i) => {
              const isConnected = isDeviceConnected(device);

              return (
                <SwiperSlide
                  key={i}
                  className="device-carousel-slide"
                  style={{
                    backgroundImage: `url(${getDeviceHomeScreenImg(device)})`,
                    paddingTop: `${headerHeight}px`,
                  }}
                >
                  <img
                    {...(i === currSlide && { ref: deviceImgRef })}
                    className={cx("device-img", {
                      disconnected: !isConnected,
                    })}
                    src={getDeviceIconImg(device)}
                    alt="device icon"
                  />
                  {!isConnected && (
                    <ReconnectCTA
                      {...(i === currSlide && { ref: reconnectCTARef })}
                      style={{
                        top: `${
                          headerHeight +
                          (deviceImgHeight / 2 -
                            getElementHeight(reconnectCTARef.current) / 2)
                        }px`,
                      }}
                      device={device}
                    >
                      {dictionary.HOME_BTN_RECONNECT_0_ctaLabel}
                    </ReconnectCTA>
                  )}
                  <FirmwareUpdateCTA
                    {...(i === currSlide && { ref: reconnectCTARef })}
                    style={{
                      top: `${
                        headerHeight +
                        (deviceImgHeight / 2 -
                          getElementHeight(reconnectCTARef.current) / 2)
                      }px`,
                    }}
                    device={device}
                    isButtonVisible={isConnected && device.updatable}
                    onStart={() => setIsUpdating(true)}
                    onSuccess={() => setIsUpdating(false)}
                  >
                    {dictionary.HOME_BTN_FW_UPDATE}
                  </FirmwareUpdateCTA>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <DeviceSelector
            ref={deviceSelectorRef}
            top={`${headerHeight + deviceImgHeight + DEVICE_IMG_MB}px`}
            devices={devices}
            selectedDeviceIndex={currSlide}
            onAdd={handleAvailableDeviceSelection}
            onSelect={(index) => {swiper.slideTo(index); localStorage.setItem('analytics_device_index', index)}}
          />
          <BottomSheet
            ref={drawerRef}
            style={{ "--rsbs-overlay-rounded": "25px" }}
            className={cx('homescreen', { scrollable: isScrollable })}
            open={true}
            blocking={false}
            expandOnContentDrag={true}
            scrollLocking={false}
            skipInitialTransition={true}
            snapPoints={({ maxHeight }) => [
              maxHeight -
                headerHeight -
                deviceImgHeight -
                DEVICE_IMG_MB -
                deviceSelectorHeight -
                DEVICE_SELECTOR_MB,
              maxHeight - headerHeight,
            ]}
            header={
              <StatusBar
                device={currDevice}
                connectedLabel={dictionary.HOME_LABEL_CONNECTED}
                disconnectedLabel={dictionary.HOME_LABEL_DISCONNECTED}
              />
            }
            onSpringStart={handleSpringStart}
          >
            <FeatureWidgetGroup>
              {widgets.map((row) =>
                row.map((featureName, i) => (
                  <FeatureWidget
                    key={i}
                    className={widgetConfig?.[featureName]?.className}
                    style={{
                      ...(widgetConfig?.[featureName]?.hasBackground && {
                        backgroundImage: `url(${getFeatureWidgetImg(
                          featureName,
                          row.length === 1
                        )})`,
                      }),
                    }}
                    disabled={widgetConfig?.[featureName]?.disabled}
                    full={row.length === 1}
                    isSupported={widgetConfig?.[featureName]?.isSupported}
                    icon={widgetConfig?.[featureName]?.icon}
                    label={
                      dictionary?.[`${widgetConfig?.[featureName]?.label}`]
                    }
                    onClick={widgetConfig?.[featureName]?.onClick}
                  />
                ))
              )}
            </FeatureWidgetGroup>
            <LockSwitch className="home-switch" device={currDevice} />
            <CustomButton
              variant="dark"
              full={true}
              icon="settings"
              className="homescreen-cta"
              onClick={goToDeviceSettings}
            >
              {dictionary.HOME_BTN_DEVICE_SETTINGS}
            </CustomButton>
          </BottomSheet>
          <ReconnectLoader />
          {!isUpdating && currDevice?.ongoingUpdate && (
            <FirmwareUpdate show={true} resumeUpdate={true} fromCTA={true} />
          )}
        </>
      ) : (
        <NoDevices />
      )}

      {isDisconnected &&
      <div className={cx('alert-disconnected', { open: isOpenDisconnectedAlert })} onClick={alertDisconnectedOnClick}>
        <div className="container-icon">
          <img src={imagesConstants.DISCONNECTED_CLOUD} />
        </div>
        <div>
          <div className="main">
            {dictionary.HOME_BLUETOOTH_OFF}
          </div>
          <div className="sub">
            {dictionary.HOME_ALERT_CONNECTIONS_BLUETOOTH}
          </div>
        </div>
      </div>
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  devices: state?.deviceReducer?.devices || [],
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedDevice: (device) =>
    dispatch(dashboardActions.setUsageTrackerSelectedDevice(device)),
  setSelected(device, successCb) {
    dispatch(deviceActions.setSelected(device, successCb));
  },
});

const connnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeScreen);
export { connnectedComponent as HomeScreen };
