import React from 'react';
import { imagesConstants } from '../../_constants';
import BrowserHelper from '../../_helpers/browser/browser.helper';
import { PermissionsHelper } from '../../_helpers/permissions/permissions.helper';
import { CustomLoadingButton } from '../CustomLoadingButton';

export const MissingOSNotificationPermissionPanel = (props) => {
  const { dictionary, onContinue, onTryAgain, isContinueButtonLoading } = props;

  const buttonClickHandler = () => {
    BrowserHelper.checkOSNotificationPermissionStatus().then(
      ({ state: osPermissionStatus }) => {
        if (PermissionsHelper.hasGrantedStatus(osPermissionStatus)) {
          onTryAgain();
        } else {
          onContinue();
        }
      }
    );
  };

  return (
    <div className="notification-permission-panel">
      <div className="text">
        <span>{dictionary.IOS_MKT_NOTIF_MISSING_PERMISSION_BODY}</span>
      </div>
      <div className="instructions-container">
        <ol>
          <li>
            <img className="icon" src={imagesConstants.SYSTEM_ICON} alt="Settings logo" />
            <span>{dictionary.IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_FIRST}</span>
          </li>
          <li>
            <img className="icon" src={imagesConstants.NUVIU_ICON} alt="Nuviu logo" />
            <span>{dictionary.IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_SECOND}</span>
          </li>
          <li>
            <img className="icon" src={imagesConstants.NOTIFICATIONS_ICON} alt="Notifications logo" />
            <span>{dictionary.IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_THIRD}</span>
          </li>
          <li>
            <img className="icon" src={imagesConstants.TOGGLE_ICON} alt="Toggle logo" />
            <span>{dictionary.IOS_MKT_NOTIF_MISSING_PERMISSION_LIST_FOURTH}</span>
          </li>
        </ol>
      </div>
      <div>
        <div className="d-grid gap-2 buttons-container">
          <CustomLoadingButton
            isLoading={isContinueButtonLoading}
            validationMethod={null}
            buttonLabel={dictionary.IOS_MKT_NOTIF_MISSING_PERMISSION_BTN_CONTINUE_0_ctaLabel}
            onClickMethod={() => buttonClickHandler()}
            buttonClassName="btn btn-primary text-uppercase"
          />
        </div>
      </div>
    </div>
  );
};
