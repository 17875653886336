import UrlGenerator from '../_helpers/api/urlGenerator';
import * as Uri from '../_constants/api/uri.constants';
import IoTPlatformUtils from '../_helpers/api/IoTPlatformUtils';
import { getUserData } from '../_actions';
import { axiosRequest, axiosEnqueuedRequest } from '../_helpers/api/axiosRequest';

export const rulesServices = {
    getAllRules,
    deleteRule,
    createRule,
    updateRule
};

async function getAllRules(tenantUserId, groupId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.rules)+`?groupId=${groupId}`,
        method: 'GET',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache'
    };

    return axiosRequest(requestOptions);
}

async function deleteRule(tenantUserId, ruleId) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.rules)+`/${ruleId}`,
        params: {
            groupId: getUserData()?.group_id
        },
        method: 'DELETE',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache'
    };

    return axiosEnqueuedRequest(requestOptions);
}

async function createRule(tenantUserId, data) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.rules),
        params: {
            groupId: getUserData()?.group_id
        },
        method: 'POST',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache',
        data
    };

    return axiosRequest(requestOptions);
}

async function updateRule(tenantUserId, data) {
    let requestOptions = {
        url: UrlGenerator.createIoTPlatformUrlFor(Uri.rules),
        params: {
            groupId: getUserData()?.group_id
        },
        method: 'PUT',
        headers: IoTPlatformUtils.createIoTPlatformHeaders(tenantUserId),
        cache: 'no-cache',
        data
    };

    return axiosRequest(requestOptions);
}