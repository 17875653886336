import React, { useEffect } from 'react';
import { CustomLoadingButton } from '../CustomLoadingButton';
import parse from 'html-react-parser';
import initialize from '../../_helpers/utils/initializeDb';
import { updateCalculatedData, updateDb } from '../../_helpers/usage-tracker';
import { isSupportedService } from '../../_actions/appConfig.actions';
import { AnalyticsEvents, AnalyticsTargetEventsNames, servicesConstants, crmUserSettings } from '../../_constants';
import { logAnalyticsEvent } from '../../_helpers/analytics/logAnalytics';
import { PageSelectorCTA } from '../PageSelectorCTA';
import { getUserData, userActions } from '../../_actions';
import { store } from '../../_store';
import { isNil } from 'lodash';
import moment from "moment";

export const VaultRestored = (props) => {
    const {
        dictionary,
        isResetPinFlow,
        isLoading
    } = props;

    const goToNextStep = () => {
        if (typeof props.goToNextStep === 'function') {
            logAnalyticsEvent(AnalyticsEvents.CLICK, {target: AnalyticsTargetEventsNames.NEXT})
            
            let utm_data = localStorage.getItem('utm_data')
            if(utm_data && !isNil(getUserData()?.id)){
                console.log(utm_data)
                const obj = JSON.parse(utm_data);
                obj['timestamp'] = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
                store.dispatch(userActions.setUserSettings(crmUserSettings.UTM_DATA, JSON.stringify(obj)))
                localStorage.removeItem('utm_data')
            }
            props.goToNextStep();
        }
    }

    useEffect(() => {
        // Initialize RXDB
        if (isSupportedService(servicesConstants.USAGE_TRACKER)) {
            initialize();
        }
        
        store.dispatch(userActions.setUserSettings(crmUserSettings.MYVUSE_LOGIN, "true"));
    }, []);

    useEffect(() => {
        if (isSupportedService(servicesConstants.USAGE_TRACKER)) {
            if (!isResetPinFlow) {
                updateDb();   
            }
        }
    }, [isResetPinFlow]);

    return (
        <React.Fragment>
            <div className="mt-auto">
                <div className="vault-restored">
                    <h1>{parse(dictionary.VAULT_SUCCESS_TITLE)}</h1>
                </div>
                <PageSelectorCTA sticky={false}>
                    <div className="d-grid">
                        <CustomLoadingButton isLoading={isLoading}
                            validationMethod={null}
                            buttonLabel={dictionary.VAULT_SUCCESS_BTN_CONTINUE_0_ctaLabel}
                            onClickMethod={goToNextStep} />

                    </div>
                </PageSelectorCTA>
            </div>
        </React.Fragment>
    );
}