import Timeout from "await-timeout";
import _, { isEmpty, isNil, isNull } from "lodash";
import moment from "moment";
import { SimpleIntervalJob, Task, ToadScheduler } from "toad-scheduler";
import { commonsActions, userActions } from "../../_actions";
import {
  getMarketCountryCode,
  getMarketPayloadCode,
  getMarketUrl,
  getSupportedServices,
  getTenantId,
  isSupportedService,
} from "../../_actions/appConfig.actions";
import {
  getAccessToken,
  getCustomerId,
  getErrorLogs,
  getUserData,
  getVuseUuid,
  setErrorLogs,
  setGroupId,
} from "../../_actions/appData.actions";
import { deviceActions } from "../../_actions/device.actions";
import {
  AnalyticsEvents,
  CUSTOMER,
  DEVICE_NAME_ALLOWED_CHARS_RE,
  DEVICE_NAME_MAX_CHARS,
  PROFILE_EPOD3,
  SKIN_COLOR_BLACK,
  VUSE_DEVICE,
  PROFILE_EPOD2,
  PROFILE_WAWE2,
  crmUserSettings,
  flagStatus,
  servicesConstants,
  thingDeviceProperties,
  thingVuseProperties,
} from "../../_constants";
import { DeviceModel, NewDeviceThing } from "../../_models";
import { store } from "../../_store";
import sdk from "../../_vendors/nodes";
import BrowserHelper from "../browser/browser.helper";
import { Commons } from "../commons";
import { Notifications } from "../notifications";
import environmentConstants from "../../_constants/environment/environment.constants";
import { errorLogModel } from "../../_models/errorLog.model";
import {
  getAnalyticStatusBy,
  logAnalyticsEventForDevice,
  logAnalyticsEvent
} from "../analytics/logAnalytics";
import { debug } from "../debug";
import {
  getUsageTrackerOptInDate,
  sendRawData,
  updateRawData,
  updateStats,
  sendCalculatedData,
  isUsageTrackerEnabled,
} from "../usage-tracker";
import HMAC_SHA256 from "crypto-js/hmac-sha256";

const DEVICE_PROFILE = "ePod2";

async function getDeviceInstanceFromSN(serialNumber) {
  let index = window.deviceList?.findIndex(
    (device) => device.serialNumber === serialNumber
  );
  if (index !== null && index !== -1) {
    return window.deviceList[index].instance;
  } else {
    return null;
  }
}

function getDeviceInstanceFromStore(serialNumber) {
  const {
    deviceReducer: { devices = [] },
  } = store.getState();

  return devices.find((device) => device.serialNumber === serialNumber);
}

let scheduler = new ToadScheduler();

/**
 * Handle progress of market selector process
 *
 * @param {*} state
 */
async function setDeviceMSSProgressStateChanged(state, deviceInstance) {
  debug(`[Market selector] state -> `, deviceInstance.MSSProgressState[state]);

  switch (state) {
    case deviceInstance.MSSProgressState.Completed: {
      /* update device in reducer */
      updateDeviceInReducer(
        store.getState().deviceReducer?.devices[0]?.serialNumber,
        { marketSelectorCompleted: true },
        true
      );
      break;
    }

    case deviceInstance.MSSProgressState.Disconnecting: {
      if (BrowserHelper.isBLEBrowser()) {
        dispatchDisconnectEvent();
      }
      break;
    }

    case deviceInstance.MSSProgressState.Error: {
      dispatchPairingErrorEvent();
      break;
    }

    default:
      break;
  }
}

async function setDeviceConnectionStateChanged(state, deviceInstance, isAutoreconnect = false) {
  debug(`[Pairing] state: ${deviceInstance.ConnectionState[state]}`);

  /* Set the state of the device -> update redux informations */
  let devicePayload = {
    connectionState: state,
  };

  console.debug("DISCONNECTING_DEVICE", state, deviceInstance);

  switch (state) {
    /* ====================== */
    /*      SYNCHRONIZED      */
    /* ====================== */
    case deviceInstance.ConnectionState.Synchronized: {
      /* Retrieve the instance of the device */
      debug("[ConnectionState.Synchronized] Reading deviceInstance...");

      /* Always set time to device after connection */
      await deviceInstance.setDateTimeConfig(Date.now() / 1000);

      /* Read device infos */
      debug(
        "[ConnectionState.Synchronized] call deviceInstance.getDeviceInfo()"
      );
      await deviceInstance
        .getDeviceInfo()
        .then(async (deviceInfo) => {
          removeExistingDevices(deviceInfo.serialNumber);

          debug(`[Pairing] DeviceInfo: `, deviceInfo);
          /* Create device thing in BE */
          const deviceUuid = await createDeviceThing(deviceInfo);
          debug(`[Pairing] deviceUuid: `, deviceUuid);

          /* Search in redux for serialNumber */
          let idxReducer = store
            .getState()
            .deviceReducer.devices?.findIndex(
              (device) => device.serialNumber === deviceInfo.serialNumber
            );
          if (null != idxReducer && idxReducer !== -1) {
            devicePayload = {
              ...store.getState().deviceReducer.devices[idxReducer],
              deviceInfo: deviceInfo,
              connectionState: deviceInstance.ConnectionState.Synchronized,
              lastConnection: new Date(),
              dfuModeOn: false,
            };
          } else {
            /* If not found, create a new object */
            devicePayload = {
              ...devicePayload,
              deviceType: store.getState().deviceReducer.deviceProfile || "",
              deviceInfo: deviceInfo,
              serialNumber: deviceInfo.serialNumber,
              lastConnection: new Date(),
              dfuModeOn: false,
              ongoingUpdate: false,
              deviceColor: SKIN_COLOR_BLACK,
            };
          }

          devicePayload = new DeviceModel(devicePayload);
          log(
            "[setDeviceConnectionStateChanged] New datamodel: " +
              JSON.stringify(devicePayload)
          );

          console.debug("[Pairing] DEV_INSTANCE", deviceInstance);

          const payloadInfos = await deviceInstance.getDeviceMSSPayload();
          debug(
            `[Pairing] Current MSS code: `,
            payloadInfos?.payload?.toString()
          );
          debug(
            `[Pairing] New MSS payload code: `,
            getMarketPayloadCode(devicePayload?.deviceType)
          );
          debug(
            `[Pairing] New MSS country code: `,
            getMarketCountryCode(devicePayload?.deviceType)
          );

          /* Check if market selector is already done */
          if (
            payloadInfos?.payload?.toString() ===
            getMarketPayloadCode(devicePayload?.deviceType)
          ) {
            //payloadInfos?.payload?.toString() === getMarketPayloadCode()
            /* Add or update the redux */
            store.dispatch(
              deviceActions.addOrUpdateDevice(devicePayload.toObj(), true, () =>
                completePairingProcess(deviceInstance, deviceInfo, deviceUuid)
              )
            );

            let devicePairedData = store.getState()?.deviceReducer?.devices.map((item) => ({ 
              displayName: item.deviceInfo.deviceName ?? '',
              serialNumber: HMAC_SHA256(
                item.deviceInfo.serialNumber,
                store.getState().onboardingReducer.userPin
              ).toString(),
              type: item.deviceType,
              firmwareVersion: item.deviceInfo.firmwareVersion,
              payload: getMarketPayloadCode(item.deviceType)
            }));

            store.dispatch(
              userActions.setUserSettings(
                crmUserSettings.PAIRED_DEVICES,
                JSON.stringify(devicePairedData)
              )
            );

            store.dispatch(
              userActions.setUserSettings(
                crmUserSettings.CONNECTED_DEVICE,
                devicePayload?.deviceCustomName || deviceInfo?.deviceName || ""
              )
            );

            if (deviceUuid) {
              store.dispatch(
                commonsActions.setThings(
                  [
                    {
                      type: thingDeviceProperties.FIRMWARE_VERSION,
                      data: deviceInfo.firmwareVersion,
                    },
                  ],
                  Commons.generateTenantUserId(
                    store.getState().onboardingReducer.userPin
                  ),
                  deviceUuid,
                  VUSE_DEVICE,
                  () => {
                    debug(`[Pairing] Now handle firmare notifications... `);
                    //Handle Firmware notification on Device connection
                    Notifications.handleFirmwareNotifications(devicePayload);
                  },
                  () => {
                    debug(
                      `[Pairing] Ooops, something went wrong setting firmare version on iot plat... `
                    );
                  }
                )
              );
            }

            //Handle operations for suggestion notification
            if (
              Commons.getCustomerProperty(thingVuseProperties.DEVICEPAIR) !==
              flagStatus.ACCEPTED
            ) {
              Commons.acceptOptIn(thingVuseProperties.DEVICEPAIR);
            }
          } else {
            debug(`[Pairing] Start Market Selector`);
            /* Perform market selector */
            if (
              getSupportedServices().includes(
                servicesConstants.ONBOARDING_MARKET_ENABLE
              )
            ) {
              dispatchMSSStartEvent();

              store.dispatch(
                deviceActions.addOrUpdateDevice(devicePayload.toObj(), true)
              );

              /* Market enable software */
              deviceInstance.setMarketSpecificSoftware({
                challengeCode: "0x0001",
                countryCode: getMarketCountryCode(devicePayload?.deviceType),
                payloadCode: getMarketPayloadCode(devicePayload?.deviceType),
                apiParams: {
                  url: getMarketUrl(),
                  headers: {
                    "x-ecommerce-customer-id": getCustomerId(),
                    "x-ecommerce-customer-token": getAccessToken(),
                    "x-tenant-id": getTenantId(),
                  },
                },
              }); // start setting up Market Specific software (fully automated process)
            }
          }

          if (isAutoreconnect) {
            setTimeout(() => {
              const devices = store.getState()?.deviceReducer?.devices
              devices.forEach(device => {
                if (device.connectionState === deviceInstance.ConnectionState.Connecting) {
                  updateDeviceInReducer(
                    device.serialNumber,
                    {
                      connectionState: deviceInstance.ConnectionState.Idle,
                    },
                    false
                  )
                }
              })
            }, 35 * 1000)
          }

        })
        .catch((e) => {
          debug(`[Pairing] Unable to Read Device Info `, e);
          //dispatch failure error to be handled by components
          dispatchPairingErrorEvent();
        });
      break;
    }

    case deviceInstance.ConnectionState.Connected:
      break;
    case deviceInstance.ConnectionState.Connecting: {
      if (isAutoreconnect) {
        const devices = store.getState()?.deviceReducer?.devices
        devices.forEach(device => {
          if (device.connectionState === deviceInstance.ConnectionState.Disconnecting) {
            updateDeviceInReducer(
              device.serialNumber,
              {
                connectionState: deviceInstance.ConnectionState.Connecting,
              },
              false
            )
          }
        })
      } else {
        dispatchPairingStartEvent();
      }
      break;
    }
    /* ======================= */
    /*      DISCONNECTING      */
    /* ======================= */
    case deviceInstance.ConnectionState.Disconnecting: {
      console.debug("DISCONNECTING_DEVICE", state, deviceInstance);
      //get disconnected device's id
      const deviceId = deviceInstance?.getBleDevice()?.id;
      //update reducer with new device properties
      let deviceReducer = updateDeviceInReducer(
        deviceId,
        {
          connectionState: deviceInstance.ConnectionState.Disconnecting,
          batteryInfo: null,
          // lockInfo: null,
          cloudInfo: null,
        },
        false,
        null,
        true
      );

      //remove job in location retrieval
      if (!_.isEmpty(deviceReducer)) {
        // stop & remove fmv job
        log("[disconnecting] scheduler", scheduler);
        log("[disconnecting] scheduler.jobRegistry", scheduler?.jobRegistry);

        if (!_.isEmpty(scheduler?.jobRegistry)) {
          try {
            scheduler.stopById(
              `fmv-location-retrieval-job-${deviceReducer.serialNumber}`
            );
            scheduler.removeById(
              `fmv-location-retrieval-job-${deviceReducer.serialNumber}`
            );
          } catch (err) {
            console.debug(err);
          }
        }
      }

      //Remove instance from global var
      if (window?.deviceList) {
        /**
         * If device is in dfu mode dont disconnect device (UI side)
         * because user should be able to retry firmware update.
         * If device is no more present in reducer, remove instance.
         */
        if (_.isEmpty(deviceReducer) || deviceReducer?.dfuModeOn !== true) {
          //get instance based on device's id
          let deviceInstanceIdx = window.deviceList?.findIndex(
            (_) => _.id === deviceId
          );
          if (deviceInstanceIdx !== null && deviceInstanceIdx !== -1) {
            //remove device listeners
            removeDeviceListeners(
              window.deviceList[deviceInstanceIdx].instance
            );

            await Timeout.set(1000);

            //remove instance from global var
            window.deviceList.splice(deviceInstanceIdx, 1);
          }
        }
      }

      dispatchDisconnectEvent();

      break;
    }
    case deviceInstance.ConnectionState.Scanning:
      break;
    case deviceInstance.ConnectionState.ScanTimout: {
      dispatchSearchingErrorEvent();
      break;
    }
    default:
      break;
  }
}

/**
 * Creates device thing if not already present, load it if present
 * @param {*} deviceInfo
 * @returns
 */
function createDeviceThing(deviceInfo) {
  return new Promise(async (resolve, reject) => {
    //load thing from reducer
    //if present resolve promise with current thing
    const deviceThing = getDeviceThing(deviceInfo.serialNumber);

    const userPin = store.getState().onboardingReducer.userPin;
    const tenantUserId = Commons.generateTenantUserId(userPin);
    const deviceProfile = store.getState().deviceReducer.deviceProfile || "";

    log(`[createDeviceThing] deviceThing`, deviceThing);
    if (null != deviceThing) {
      debug(`[Pairing] Thing present`);
      resolve(deviceThing?.uuid);
    } else {
      debug(`[Pairing] Thing missing`);
      //if thing not present in reducer create a new one

      store.dispatch(
        commonsActions.getGroup(
          tenantUserId,
          (response) => {
            const newGroupId = response?.data?.[0]?.id;

            setGroupId(newGroupId);

            const newDeviceModel = new NewDeviceThing({
              deviceType: deviceProfile,
              serialNumber: deviceInfo.serialNumber,
              groupId: newGroupId ? newGroupId : "",
              firmwareVersion: deviceInfo.firmwareVersion ?? "",
            });

            log(`[createDeviceThing] userPin: ${userPin}`);
            log(`[createDeviceThing] tenantUserId: ${tenantUserId}`);
            log(`[createDeviceThing] newDeviceModel: ${newDeviceModel}`);

            /* update BE thing */
            store.dispatch(
              commonsActions.addThing(
                tenantUserId,
                newDeviceModel.payload(),
                (response) => {
                  let deviceUuid = null;
                  if (response !== null) {
                    log("createDeviceThing response", response);
                    const thingDevice = response.data.filter(
                      (thing) =>
                        thing.vendor === VUSE_DEVICE &&
                        thing.properties.find(
                          (property) =>
                            property.type ===
                            thingDeviceProperties.SERIAL_NUMBER
                        )?.data === deviceInfo.serialNumber
                    );
                    log(
                      "createDeviceThing deviceInfo.serialNumber",
                      deviceInfo.serialNumber
                    );
                    log("createDeviceThing thingDevice", thingDevice[0]);
                    deviceUuid = thingDevice[0]?.uuid;
                    store.dispatch(
                      commonsActions.setThings(
                        [
                          {
                            type: thingVuseProperties.MKT_DEVICETYPE,
                            data:
                              deviceProfile === PROFILE_EPOD3
                                ? "EPOD_3"
                                : "EPOD_2",
                          },
                        ],
                        tenantUserId,
                        getVuseUuid(),
                        CUSTOMER
                      )
                    );
                    resolve(deviceUuid);
                  }
                  resolve(null);
                },
                () => {
                  resolve(null);
                }
              )
            );
          },
          () => {
            resolve(null)
          }
        )
      )
    }
  });
}

function completePairingProcess(deviceInstance, deviceInfo, deviceUuid) {
  debug(`[Pairing] loadDeviceCharacteristics`);

  /* Load all cuurent characteristics */
  return loadDeviceCharacteristics(
    deviceInstance,
    deviceInfo.serialNumber,
    deviceUuid
  )
    .then(() => {
      /* Manage global array of device instances */
      let index = window.deviceList?.findIndex(
        (device) => device.serialNumber === deviceInfo.serialNumber
      );
      console.log("[CURRENT DEVICE] deviceInstance ", deviceInstance);
      console.log("[CURRENT DEVICE] index ", index);
      console.log("[CURRENT DEVICE] window.deviceLis ", window.deviceList);
      if (index !== null && index !== -1) {
        window.deviceList[index].instance = deviceInstance;
      } else {
        let deviceEntry = {
          serialNumber: deviceInfo.serialNumber,
          instance: Object.assign(
            Object.create(Object.getPrototypeOf(deviceInstance)),
            deviceInstance
          ),
          id: deviceInfo.deviceId,
        };
        window.deviceList.push(deviceEntry);
      }

      console.log(
        "[CURRENT DEVICE] devicwindow.deviceLiseInstance ",
        window.deviceList
      );

      /* TODO: insert eventuallySecure AV */

      /* Instantiate listeners for characteristics changing */
      addDeviceListeners(deviceInstance, deviceInfo.serialNumber, deviceUuid);

      /* Emit that everything was succesfull */
      dispatchPairingSuccessEvent(deviceInfo.serialNumber);

      /* USAGE TRACKER PUFFS SUBSCRIPTION */

      initSubscriber(deviceInstance, deviceInfo)      
    })
    .catch((e) => {
      debug(`[Pairing] Error during loadDeviceCharacteristics: `, e);
      dispatchPairingErrorEvent();
    });
}

function initSubscriber(deviceInstance, deviceInfo) {
  if (isSupportedService(servicesConstants.USAGE_TRACKER)) {
    // createDummyPuffs(deviceInstance);

    const usageTrackerOptinDate = getUsageTrackerOptInDate();

    console.debug("USAGE_TRACKER_OPTIN_DATE", usageTrackerOptinDate);
    debug(
      "USAGE_TRACKER_OPTIN_DATE" + JSON.stringify(usageTrackerOptinDate)
    );

    let startOfFile = false

    deviceInstance
      .subscribeToPuffRecord(async (puffRecord) => {
        if (
          !isUsageTrackerEnabled()
        ) {
          return;
        } else if (puffRecord.hasOwnProperty("startOfFile")) {
          startOfFile = true
        } else {
          //TEST
          if (puffRecord.timestamp == '4294967295') {
            puffRecord.timestamp = moment().unix()
            
            puffRecord.powerLevel = Math.floor(Math.random() * (65 - 25 + 1)) + 25
            puffRecord.duration = 0.6
          }

          const puffDate = moment(puffRecord.timestamp * 1000)

          debug("PUFF DATE" + " " + JSON.stringify(puffDate));
          debug("PUFF DATE IS_VALID" + " " + `${puffDate.isValid()}`);
          debug(
            "PUFF DATE IS_BEFORE" +
              " " +
              `${puffDate.isBefore(usageTrackerOptinDate)}`
          );

          if (
            puffDate.isValid() &&
            puffDate.isAfter(usageTrackerOptinDate)
          ) {
            updateLocalStorageBuffer(deviceInfo, puffRecord)
          } else {
            debug("PUFFS TO REJECT" + JSON.stringify(puffRecord));
            console.debug("PUFFS TO REJECT", puffRecord);
          }
        }

        if (puffRecord.hasOwnProperty("endOfFile") || !startOfFile) {
          startOfFile = false

          const buffer = getLocalStorageBuffer(deviceInfo)
          if (buffer && buffer?.records && buffer?.records?.length) {
            sendData(deviceInfo, buffer.records)
          }
        }
      })
      .catch((e) => e);
  }
}

function getLocalStorageBuffer(deviceInfo) {
  const storageName = 'puffRecordsBuffer' + deviceInfo.serialNumber
  const buffer = JSON.parse(localStorage.getItem(storageName))

  return buffer
}

function updateLocalStorageBuffer(deviceInfo, record) {
  const storageName = 'puffRecordsBuffer' + deviceInfo.serialNumber
  let buffer = JSON.parse(localStorage.getItem(storageName))

  if (isNil(buffer)) {
    buffer = {records: []}
  }

  buffer.records.push(record)

  localStorage.setItem(storageName, JSON.stringify(buffer))
}

function deleteLocalStorageBuffer(deviceInfo) {
  const storageName = 'puffRecordsBuffer' + deviceInfo.serialNumber

  localStorage.removeItem(storageName)
}

function sendData(deviceInfo, records) {
  if (records.length) {
    updateStats(deviceInfo.serialNumber, records).then(() => {
      /**
       * Send calculated data to the BE
       */
      sendCalculatedData();

      //TEST
      if (isSupportedService(servicesConstants.USAGE_TRACKER_RAW)) {
        /**
         * Store raw data in the db
         **/
        updateRawData(deviceInfo.serialNumber, records).then(() => {
          deleteLocalStorageBuffer(deviceInfo)
          /**
           * Publish raw data to BE
           */
          sendRawData()
        })
      } else {
        deleteLocalStorageBuffer(deviceInfo)
      }
    })
  }
}

async function loadDeviceCharacteristics(
  deviceInstance,
  serialNumber,
  deviceUuid
) {
  return new Promise(async function (resolve, reject) {
    let batteryInfo = null;
    let lockInfo = null;
    let cloudInfo = null;
    let ledInfo = null;
    let findMyVapeInfo = null;

    try {
      lockInfo = await deviceInstance.getLockInfo();
      debug("[loadDeviceCharacteristics] lockInfo", lockInfo);

      batteryInfo = await deviceInstance.getBatteryInfo();
      debug("[loadDeviceCharacteristics] batteryInfo", batteryInfo);

      cloudInfo = await deviceInstance.getCloudInfo();
      debug("[loadDeviceCharacteristics] cloudInfo", cloudInfo);

      ledInfo = await deviceInstance.getLedInfo();
      debug("[loadDeviceCharacteristics] ledInfo", ledInfo);

      findMyVapeInfo = await deviceInstance.getFindVapeInfo();
      debug("[loadDeviceCharacteristics] findMyVapeInfo", findMyVapeInfo);

      let devicePayload = updateDeviceInReducer(
        serialNumber,
        {
          batteryInfo: batteryInfo,
          lockInfo: lockInfo,
          cloudInfo: cloudInfo,
          ledInfo: ledInfo,
          isBlinking: findMyVapeInfo?.alertValue === 1,
        },
        true,
        () => resolve()
      );

      if (!_.isEmpty(devicePayload)) {
        if (deviceUuid !== null && deviceUuid !== undefined) {
          const userPin = store.getState().onboardingReducer.userPin;
          const tenantUserId = Commons.generateTenantUserId(userPin);

          console.log("cloudInfo", cloudInfo);
          store.dispatch(
            deviceActions.setDeviceCloud(
              tenantUserId,
              deviceUuid,
              cloudInfo && (cloudInfo.powerLevel / 10).toFixed(1)
            )
          );
          //store.dispatch(deviceActions.setLedBrightness(tenantUserId, deviceUuid, ledInfo.brightness));
        }
      }
    } catch (e) {
      debug(`[Pairing] Unable to Read characteristics `, e);
      reject(e);
    }
  });
}

async function addDeviceListeners(deviceInstance, serialNumber, deviceUuid) {
  //debug(`[Pairing] addDeviceListeners`, JSON.stringify(deviceInstance));
  await Timeout.set(500);

  const userPin = store.getState().onboardingReducer.userPin;
  const tenantUserId = Commons.generateTenantUserId(userPin);

  /* Handle notifications on lock info */
  deviceInstance.subscribeToLockInfo((response) => {
    debug(`[Device] LockInfo: `, response);
    console.log(
      "[CURRENT DEVICE] [subscribeToLockInfo]",
      serialNumber,
      response
    );
    updateDeviceInReducer(serialNumber, { lockInfo: response });
    store.dispatch(
      deviceActions.setDeviceLock(tenantUserId, deviceUuid, response.locked)
    );
  });

  /* Handle notifications on battery info */
  deviceInstance.subscribeToBatteryInfo((response) => {
    debug(`[Device] BatteryInfo: `, response);
    console.log(
      "[CURRENT DEVICE] [subscribeToBatteryInfo]",
      serialNumber,
      response
    );

    //UPDATE REDUCER
    const devicePayload = updateDeviceInReducer(serialNumber, {
      batteryInfo: response,
    });

    //UPDATE BE
    store.dispatch(
      deviceActions.setDeviceBattery(
        tenantUserId,
        deviceUuid,
        response.chargeLevel
      )
    );

    //HANDLE NOTIFICATIONS
    Notifications.handleBatteryLevelNotifications(response, devicePayload);
  });

  /* Handle notifications on find my vape info */
  deviceInstance.subscribeToFindVapeInfo((response) => {
    console.log(
      "[CURRENT DEVICE] [subscribeToFindVapeInfo]",
      serialNumber,
      response
    );
    debug(`[Device] FindVapeInfo: `, response);
    updateDeviceInReducer(serialNumber, {
      isBlinking: response?.alertValue === 1,
    });
    store.dispatch(
      deviceActions.setDeviceFlash(
        tenantUserId,
        deviceUuid,
        response.alertDuration
      )
    );
  });

  deviceInstance.subscribeToCloud((res) => {
    const devices = store.getState()?.deviceReducer?.devices || [];
    const currentDevice = devices.find((d) => d.serialNumber === serialNumber);

    console.debug("subscribeToCloud - prevCloudInfo", currentDevice?.cloudInfo);
    console.debug("subscribeToCloud - currCloudInfo", res);

    if (
      res?.batterySavingEnabled === 1 &&
      currentDevice?.cloudInfo?.batterySavingOn !== res?.batterySavingOn
    ) {
      logAnalyticsEventForDevice(
        currentDevice,
        AnalyticsEvents.BATTERY_SAVER_STATUS,
        {
          Status: getAnalyticStatusBy(!!res?.batterySavingOn),
          device_bs_engaged: getAnalyticStatusBy(!!res?.batterySavingOn),
          epodName: `${currentDevice?.deviceCustomName ?? null}`
        }
      );

      Notifications.handleBatterySaverNotifications({
        ...currentDevice,
        cloudInfo: res,
      });
    }

    store.dispatch(
      deviceActions.setDeviceCloud(
        tenantUserId,
        deviceUuid,
        (res.powerLevel / 10).toFixed(1)
      )
    );

    updateDeviceInReducer(serialNumber, {
      cloudInfo: res,
    });
  });

  deviceInstance.subscribeToErrorRecord((errorLog) => {
    debug(`[Device] ErrorRecord: `, errorLog);
    console.log(
      "[CURRENT DEVICE] [subscribeToErrorRecord]",
      serialNumber,
      errorLog
    );

    if (
      errorLog.hasOwnProperty("endOfFile") ||
      errorLog.hasOwnProperty("startOfFile")
    ) {
      return; //start and end of files
    }

    if (errorLog?.errorCode === 0) {
      return;
    }

    const devices = store.getState().deviceReducer.devices;
    let idxReducer = devices?.findIndex(
      (device) => device.serialNumber === serialNumber
    );
    if (null != idxReducer && idxReducer !== -1) {
      log("errorLog", errorLog);

      //if user has accepted to see notifications on device errors
      if (
        Commons.getCustomerProperty(thingVuseProperties.DEVICEERROR) ===
        flagStatus.ACCEPTED
      ) {
        //if error occurred in the last 5 seconds
        if (
          moment
            .unix(errorLog.timestampUnix)
            .isSameOrAfter(moment().subtract(5, "seconds"))
        ) {
          Notifications.dispatchNotification(
            Notifications.getNotificationFromErrorCode(
              errorLog?.errorCode,
              devices[idxReducer]
            )
          );
        }
      }
    }

    //Send error to BE
    const errorLogs = [...getErrorLogs(), errorLog];

    errorLogs.forEach((log) => {
      let errorsModel = new errorLogModel();
      errorsModel.setError(
        getUserData()?.id,
        deviceUuid,
        devices[idxReducer],
        log
      );
      store.dispatch(
        deviceActions.setDeviceLogError(
          tenantUserId,
          errorsModel.payload(),
          (response) => {
            setErrorLogs(
              getErrorLogs().filter(
                (thisLog) =>
                  !(
                    _.isEqual(thisLog.timestamp, log.timestamp) &&
                    _.isEqual(thisLog.timestampUnix, log.timestampUnix) &&
                    _.isEqual(thisLog.errorCode, log.errorCode) &&
                    _.isEqual(thisLog.error, log.error)
                  )
              )
            );
          },
          (err) => {
            setErrorLogs(errorLogs);
          }
        )
      );
    });
  });

  deviceInstance.subscribeToLogRecord((log) => {
    debug(`[Device] logRecord: `, log);
  });
}

async function removeDeviceListeners(deviceInstance) {
  debug(`[Pairing] removeDeviceListeners`);
  deviceInstance.unsubscribeFromLogRecord();
  deviceInstance.unsubscribeFromErrorRecord();
  deviceInstance.unsubscribeFromFindVapeInfo();
  deviceInstance.unsubscribeFromBatteryInfo();
  deviceInstance.unsubscribeFromLockInfo();
  deviceInstance.unsubscribeFromCloud();
}

function retrieveDeviceLocation() {
  return new Promise((resolve) => {
    const optedIn = Commons.getCustomerProperty(
      thingVuseProperties.OPT_IN_FINDMYVAPE
    );

    /* if geolocation is supported and enabled */
    if (
      optedIn === flagStatus.ACCEPTED &&
      BrowserHelper.isGetCurrentPositionFromGeolocationAvailable()
    ) {
      BrowserHelper.getCurrentPosition()
        .then((position) => {
          resolve(position)
        })
        .catch(() => {
          resolve();
        });
    } else {
      resolve();
    }
  });
}

function assignDeviceLocation(device, position) {
  return new Promise((resolve) => {
    let lat = position.coords.latitude;
    let lng = position.coords.longitude;
    let date = position.lastSyncedDate;

    lat = +lat.toFixed(5);
    lng = +lng.toFixed(5);

    let devicePayload = {
      ...device,
      lastSyncedLocation: { lat, lng, date }, //: lng+(1*(Math.floor(Math.random() * 9)))
    };
    devicePayload = new DeviceModel(devicePayload);
    store.dispatch(
      deviceActions.addOrUpdateDevice(
        devicePayload.toObj(),
        false,
        () => {
          resolve();
        }
      )
    );
  })
}

/**
 * TODO: remove when multidevice
 * @param {*} serialNumber
 * @returns
 */
function removeExistingDevices(serialNumber) {
  return;

  const devices = store.getState().deviceReducer.devices;
  const preexistingDevices = devices.filter(
    (_) => _.serialNumber !== serialNumber
  );

  if (Commons.isValidArray(preexistingDevices)) {
    preexistingDevices.forEach((device) => {
      //1. remove device from reducer
      store.dispatch(deviceActions.removeDevice(device.serialNumber));

      //2. delete THING from BE
      const deviceThing = getDeviceThing(device.serialNumber);

      if (!isNil(deviceThing)) {
        store.dispatch(
          commonsActions.deleteThing(
            Commons.generateTenantUserId(
              store.getState().onboardingReducer.userPin
            ),
            deviceThing?.uuid
          )
        );
      }

      //3. delete instance
      let index = window.deviceList?.findIndex(
        (_) => _.serialNumber === serialNumber
      );
      if (index !== null && index !== -1) {
        window.deviceList.splice(index, 1);
      }
    });
  }
}

function hasLastSyncedLocation(deviceInstance) {
  return !isNil(deviceInstance.lastSyncedLocation);
}

function clearAllLastSyncedLocations() {
  debug(`[Device] clearing all synced locations`);
  const devices = store.getState().deviceReducer.devices;
  devices.forEach((device) =>
    updateDeviceInReducer(device.serialNumber, { lastSyncedLocation: null })
  );
}

function isLowPowerModeEnabledBy(device, deviceSpecs) {
  const cloudInfo = device?.cloudInfo;
  const lowPowerValue = getLowPowerValueFrom(deviceSpecs);
  const powerLevel = getPowerLevelFrom(device);

  console.debug("updateCloudConfig - isLowPowerModeEnabledBy", cloudInfo);

  // if (cloudInfo?.batterySavingEnabled === 1) {
  //   return cloudInfo?.batterySavingOn === 1;
  // }

  return lowPowerValue >= powerLevel;
}

function getLowPowerValueFrom(deviceSpecs) {
  return parseInt(deviceSpecs?.lowPower?.value ?? 1);
}

function getPowerLevelFrom(device) {
  return device?.cloudInfo?.powerLevel ?? 10;
}

function getBSPowerLevelFrom(device) {
  return device?.cloudInfo?.batterySavingPowerLevel ?? 10;
}

function getDeviceThing(serialNumber) {
  return store
    .getState()
    .commonsReducer.things.find(
      (thing) =>
        thing?.vendor === VUSE_DEVICE &&
        thing?.properties &&
        (thing?.properties).find(
          (property) => property.type === thingDeviceProperties.SERIAL_NUMBER
        )?.data === serialNumber
    );
}

/**
 *
 * @param {String} serialNumber serialnumber or id of the device we want to update
 * @param {Object} properties new properties of the dveices
 * @param {Boolean} first true if device must be put as first after update, false otherwise
 * @param {*} callBack callback to be called after update
 * @param {Boolean} isId true if first parameter is device's id, false otherwise
 * @returns
 */
function updateDeviceInReducer(
  serialNumber,
  properties,
  first = false,
  callBack = null,
  isId = false
) {
  const devices = store.getState().deviceReducer.devices;

  let idxReducer = devices?.findIndex(
    (device) =>
      (isId ? device.deviceInfo.deviceId : device.serialNumber) === serialNumber
  );
  let devicePayload = {};

  if (null != idxReducer && idxReducer !== -1) {
    //debug(`[Pairing] device in redux: ${JSON.stringify(devices[idxReducer])}`);
    devicePayload = {
      ...devices[idxReducer],
      ...properties,
    };
    debug(`[Pairing] new properties: ${JSON.stringify(properties)}`);
    debug(`[Pairing] old device in reducer: ${JSON.stringify(devicePayload)}`);
    devicePayload = new DeviceModel(devicePayload);
    store.dispatch(
      deviceActions.addOrUpdateDevice(devicePayload.toObj(), first, callBack)
    );
  }

  return devicePayload;
}

function dispatchDisconnectEvent() {
  window.document.dispatchEvent(new CustomEvent("disconnectEvent"));
}

function dispatchPairingErrorEvent() {
  debug(`[Pairing] Dispatching pairing error`);
  window.document.dispatchEvent(new CustomEvent("pairingError"));
}

function dispatchPairingStartEvent() {
  window.document.dispatchEvent(new CustomEvent("pairingStart"));
}

function dispatchPairingSuccessEvent(serialNumber) {
  debug(`[Pairing] Dispatching pairing success`);
  window.document.dispatchEvent(
    new CustomEvent("pairingSuccess", { serialNumber: serialNumber })
  );
}

function dispatchSearchingErrorEvent() {
  debug(`[Pairing] Dispatching searching error`);
  window.document.dispatchEvent(new CustomEvent("searchingError"));
}

function dispatchMSSStartEvent() {
  window.document.dispatchEvent(new CustomEvent("mssStart"));
}

function log(...args) {
  if (process.env.REACT_APP_ENV_NAME !== environmentConstants.PRODUCTION) {
    console.log(`%c[Device]`, "color:darkorange", ...args);
  }
}

function isDeviceConnected(device) {
  if (isNil(device)) {
    return false;
  }

  return (
    sdk[device.deviceType] && (sdk[device.deviceType].ConnectionState.Connected ===
      device.connectionState || isSynchronized(device))
  );
}

function isSynchronized(device) {
  if (isNil(device)) {
    return false;
  }

  return (
    sdk[device.deviceType] && (sdk[device.deviceType].ConnectionState.Synchronized ===
    device.connectionState)
  );
}

function getDeviceName(device) {
  return (
    device?.deviceCustomName ||
    device?.deviceInfo?.advertisingName ||
    device?.deviceInfo?.deviceName ||
    "ePod"
  );
}

function getCurrentDevice() {
  const devices = store.getState().deviceReducer.devices;

  return devices.find((_) => _.selected) ?? devices[0];
}

async function isBatterySaverSupported(device) {
  if (isSupportedService(servicesConstants.BATTERY_SAVER)) {
    try {
      const deviceInstance = await getDeviceInstanceFromSN(device.serialNumber);

      if (deviceInstance) {
        return await deviceInstance.isBSExisting();
      }

      return false;
    } catch (e) {
      throw new Error(e);
    }
  }

  return false;
}

async function updateCloudConfig(device, config = {}) {
  const deviceInstance = await getDeviceInstanceFromSN(device.serialNumber);

  if (deviceInstance) {
    const cloudInfo = await deviceInstance.getCloudInfo();

    if (cloudInfo) {
      let cloudConfig = {
        ...cloudInfo,
        ...config,
      };

      console.debug("updateCloudConfig cloudInfo", cloudInfo);
      console.debug("updateCloudConfig user config", config);
      console.debug("updateCloudConfig merge", cloudConfig);

      const res = await deviceInstance.setCloudConfig(
        cloudConfig.powerLevel,
        cloudConfig.batterySavingEnabled,
        cloudConfig.batterySavingThresholdValue,
        cloudConfig.batterySavingPowerLevel,
        cloudConfig.batterySavingOn
      );

      console.debug("updateCloudConfig after set", res);

      updateDeviceInReducer(device.serialNumber, {
        cloudInfo: res,
      });
    }
  }
}

async function isAdvertisingNameSupported(device) {
  if (isSupportedService(servicesConstants.EPOD_ADVERTISING_NAME)) {
    try {
      const deviceInstance = await getDeviceInstanceFromSN(
        device?.serialNumber
      );

      if (deviceInstance) {
        return await deviceInstance.isAdvNameExisting();
      }

      return false;
    } catch (e) {
      throw new Error(e);
    }
  }

  return false;
}

function isValidDeviceName(deviceName) {
  if (
    !deviceName ||
    /^\s+$/.test(deviceName) ||
    deviceName.length > DEVICE_NAME_MAX_CHARS ||
    !DEVICE_NAME_ALLOWED_CHARS_RE.test(deviceName)
  ) {
    return false;
  }

  return true;
}

function handleDeviceSelection(profile){
  const deviceProfile = profile === PROFILE_WAWE2 ? PROFILE_EPOD2 : profile;

  store.dispatch(deviceActions.setDeviceProfile(deviceProfile))
  //logAnalyticsEvent(AnalyticsEvents.DEVICE_TYPE_SELECTED, {
  //  type: deviceProfile,
  // });
}

function updateDevicesPosition() {
  if (scheduler) {
    const devices = store.getState().deviceReducer.devices;

    if (devices?.length) {
      /* Handle current device position saving */
      try {
        const taskName = `fmv-location-retrieval-task-all-devices`;
        const task = new Task(taskName, () => {
          retrieveDeviceLocation().then((position) => {
            if (position) {
                devices.forEach(device => {
                    if (isDeviceConnected(device)) {
                        assignDeviceLocation(device, position)
                    }
                })
            }
          })
        });
        const job = new SimpleIntervalJob(
          { minutes: 1, runImmediately: true },
          task,
          {id: taskName}
        );
        scheduler.addSimpleIntervalJob(job);
      } catch (err) {
        console.debug(err);
      }
    }
  }
}

export * from "./pairing";
export {
  DEVICE_PROFILE,
  setDeviceConnectionStateChanged,
  setDeviceMSSProgressStateChanged,
  getDeviceInstanceFromSN,
  getDeviceInstanceFromStore,
  updateDeviceInReducer,
  loadDeviceCharacteristics,
  isLowPowerModeEnabledBy,
  getLowPowerValueFrom,
  getPowerLevelFrom,
  retrieveDeviceLocation,
  dispatchSearchingErrorEvent,
  getDeviceThing as getDeviceThingFromSN,
  hasLastSyncedLocation,
  clearAllLastSyncedLocations,
  isSynchronized,
  isDeviceConnected,
  getDeviceName,
  getCurrentDevice,
  isBatterySaverSupported,
  updateCloudConfig,
  isAdvertisingNameSupported,
  isValidDeviceName,
  initSubscriber,
  createDeviceThing,
  handleDeviceSelection,
  assignDeviceLocation,
  updateDevicesPosition
};
